import {
    ForwardedRef,
    MouseEventHandler,
    PropsWithChildren,
    forwardRef,
    useCallback,
} from "react";
import styles from "./styles/MenuItem.module.scss";
import classNames from "classnames";

const MenuItem = (
    {
        active,
        children,
        className,
        disabled,
        icon,
        onClick,
        renderAsNode = false,
        checked,
        ...rest
    }: PropsWithChildren<{
        active?: boolean;
        className?: string;
        disabled?: boolean;
        forceFocus?: boolean;
        icon?: JSX.Element;
        onClick?: MouseEventHandler;
        value?: string;
        checked?: boolean;
        renderAsNode?: boolean;
    }>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const handleClick: MouseEventHandler = useCallback(
        (e) => {
            if (onClick && !disabled) {
                onClick(e);
            }
        },
        [disabled, onClick]
    );
    return (
        <div
            ref={ref}
            className={classNames(
                styles.root,
                {
                    [styles.active]: !checked && active,
                    [styles.disabled]: disabled,
                },
                className
            )}
            {...rest}
            onClick={handleClick}
        >   
        {checked ? 
        <input
            checked={active}
            type="checkbox"   
            className={styles.checkbox} 
        /> : null
        }
            {icon || null}
            {renderAsNode ? children : <p>{children}</p>}
        </div>
    );
};

export default forwardRef(MenuItem);
