interface Duration {
    secs: number;
}

export interface SchemaField {
    name: string;
    type: string;
    qualifiers: string[];
}

export interface Pipeline {
    name: string;
    dataset_name: string;
    input_dataset_names: string[];
    owner: string;
    code?: string;
}

export interface Feature {
    name: string;
    tags: string[];
    description: string;
    owner?: string;
    featureset?: string;
    type: string;
    lineage_tags?: string[];
}

export interface Featureset {
    name: string;
    tags: string[];
    description: string;
    status: string;
    owner: string;
    code?: string; // TODO: add code to all dummy datasets in dummy_data
    extractors: Extractor[];
    features: Feature[];
    lineage_tags?: string[];
}

export interface InputFeature {
    feature_set: string;
    name: string;
}

export interface Extractor {
    name: string;
    tags: string[];
    owner?: string;
    code: string;
    featureset?: string;
    datasets?: string[];
    inputs?: InputFeature[];
    outputs?: string[];
}

export interface Dataset extends Record<string, unknown> {
    id?: string;
    name: string;
    owner: string;
    tags: string[];
    retention: Duration;
    description: string;
    status: string;
    dsschema: SchemaField[];
    code?: string; // TODO: add code to all dummy datasets in dummy_data
    pipelines: Pipeline[];
    outgoing_pipelines: Pipeline[];
    featuresets: Featureset[];
    version?: number;
    is_source_dataset?: boolean;
    lineage_tags?: string[];
    is_expectation_defined?: boolean;
}


export enum BatchSinkStatus {
    ACTIVE = "Active",
    COMPLETED = "Completed",
}

export enum DatasetIngestionStatus {
    INITIALIZING = "Initializing",
    INGESTING = "Ingesting",
    COMPLETE = "Finished",
    FAILED = "Failed",
}

export interface BatchSinkRun {
    stats: {
        era: number,
        job_id: number,
        dataset_name: string,
        pdid: number,
        status: BatchSinkStatus,
        started_at: {
            micros: number
        },
        finished_at: {
            micros: number
        },
        num_inserts: number,
        num_deletes: number,
        num_updates: number,
        table_suffix: string,
    }[];
}

export interface DatasetIngestion {
    stats: {
        dataset_name: string;
        rows_to_ingest: number;
        rows_ingested: number;
        rows_failed: number;
        rows_skipped: number;
        started_at: string;
        updated_at: string;
        status: DatasetIngestionStatus;
        next_ingestion: number;
        source_name: string;
        failed?: boolean;
    }[];

    lifetime_stats: {
        rows_ingested: number;
        rows_failed: number;
        rows_skipped: number;
    };
}

export interface SourceExt {
    name: string;
    db: any; //any for now;
}


export interface Source {
    name: string;
    source_type: string;
    db: string;
    table: string;
    dataset?: string; // TODO: add to dummy data
}

export type CurrentViewState = {
    datasets?: Dataset[];
    extractors?: Extractor[];
    featuresets?: Featureset[];
    features?: Feature[];
    sources?: Source[];
};

export interface SourceInfo {
    [key: string]: {
        features: Feature[];
        datasets: Dataset[];
        metadata: Partial<Source>;
    };
}

export interface FeatureProps {
    feature: Feature;
    extractor: Extractor;
    featureset: Featureset;
}
