import { useCallback, useState } from "react";
import axios, { AxiosError } from "axios";
import { DeleteConfirmDialog } from "shared/components/DeleteConfirmDialog";
import { toast_json_error, toast_success } from "shared/utils/toast";

const TokenDeleteDialog = ({
    onSuccess,
    tokenName,
}: {
    onSuccess: () => void;
    tokenName?: string;
}) => {

    const onSubmit =
        useCallback(async () => {
            try {
                await axios.delete(`/api/v1/auth/token/${tokenName}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                toast_success("Token revoked");
                onSuccess();
            } catch (e) {
                toast_json_error(
                    e as AxiosError<{ detail?: string }>,
                    "Something went wrong"
                );
            }
        }, [onSuccess, tokenName]);

    return (
        <DeleteConfirmDialog 
            onSubmit={onSubmit}
            description={"This action is irreversible and once revoked, this token can no longer be used."}
            />
    );
};

export default TokenDeleteDialog;
