import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import SearchBar from "../../components/search/SearchBar";
import { useEffect, useState } from "react";
import MenuItem from "../../components/MenuItem";
import styles from "./Combobox.module.scss";
import { DropdownSeparator } from "../DropdownMenu";


interface ComboBoxItem {
    value: string;
    display: React.ReactNode;
    icon?: JSX.Element;
  }


  interface ComboBoxProps {
    items: ComboBoxItem[];
    trigger: React.ReactNode;
    selectedValues: string[];
    onSelectionChange: (selectedValues: string) => void;
    includeSelectAll?: boolean;
    selectAllLabel?: string;
    isSingleChoice?: boolean;
  }

export default function ComboBox({
    items = [],
    trigger,
    selectedValues = [],
    onSelectionChange,
    includeSelectAll = false,
    selectAllLabel = 'Select All',
    isSingleChoice
  }: ComboBoxProps) {
    const [filteredItems, setFilteredItems] = useState<ComboBoxItem[]>(items);
    const [internalSelectedValues, setInternalSelectedValues] = useState<string[]>(selectedValues);
  
    useEffect(() => {
      setInternalSelectedValues(selectedValues);
    }, [selectedValues]);
  
    const handleItemClick = (value: string) => {
      onSelectionChange(value)
    };
  
    // Determine if "Select All" should be checked
    const isAllSelected = internalSelectedValues.length === items.length;
  
    // Prepare items with "Select All" option
    const comboBoxItems = includeSelectAll
      ? [{ value: selectAllLabel, display: selectAllLabel }, ...items]
      : items;
  
    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className={styles.content}>
            <SearchBar
              onSearch={(text: string) =>
                setFilteredItems(
                  comboBoxItems.filter((i: ComboBoxItem) =>
                    i.value.toLowerCase().includes(text.toLowerCase())
                  )
                )
              }
              fill
              inputProps={{
                onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => e.stopPropagation(),
              }}
            />
            <div className={styles.items}>
              {filteredItems.map((item, idx) => {
                const isSelected =
                  item.value === selectAllLabel
                    ? isAllSelected
                    : internalSelectedValues.includes(item.value);
                return (
                  <DropdownMenu.Item
                    onClick={() => handleItemClick(item.value)}
                    key={idx}
                    className={styles.menuItemWrapper}
                  >
                    <MenuItem icon={item.icon} active={isSelected} renderAsNode checked={!isSingleChoice}>
                    <div className={styles.menuItem}>
                      {item.display}
                    </div>
                    </MenuItem>
                    {item.value === selectAllLabel && (
                      <DropdownSeparator />
                    )}
                  </DropdownMenu.Item>
                );
              })}
            </div>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );
  }