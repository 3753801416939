import LineageSection from "shared/components/LineageSection";
import { LINEAGE_NAV } from "shared/constants/navigation";
import ConsolePage from "../console-page/ConsolePage";
import { useUpdateEntityParams } from "../../context/CurrentView";
import SearchBar from "shared/components/search/SearchBar";
import { useSearchConfig, useTargetNode } from "./utils";
import { FILTER_META_SEPARATOR } from "shared/constants/constants";


function LineagePage(): JSX.Element {
    const { updateEntityParams, clearEntityParams } = useUpdateEntityParams()
    const searchConfig = useSearchConfig();
    const {defaultFilters, targetNode, setTargetNode} = useTargetNode()
    return (
        <ConsolePage
            header={{
                title: LINEAGE_NAV.title,
                icon: LINEAGE_NAV.icon,
            }}
            content={
                <LineageSection
                    focusedNode={targetNode}
                />
            }
            subheader={
                <SearchBar
                    config={searchConfig}
                    onSearch={(text, filters, force) => {

                        if (force) {
                            clearEntityParams();
                        }
                        filters.forEach((f) => {
                            if (f.key === "dataset") {
                                updateEntityParams("dataset", f.value.toString());
                            } else if (f.key === "feature") {
                                const [feature, featureset] = f.value
                                    .toString()
                                    .split(FILTER_META_SEPARATOR);
                                updateEntityParams("feature", feature, featureset);
                            } else if (f.key === "extractor") {
                                const [extractor, featureset] = f.value
                                .toString()
                                .split(FILTER_META_SEPARATOR);
                                const nodeId =  featureset + "." + extractor;
                                updateEntityParams("featureset", featureset);
                                setTargetNode(nodeId);
                            }
                        });
                    }}
                    searchHide
                    defaultFilters={
                        defaultFilters.length > 0 ? defaultFilters : undefined
                    }
                />
            }
        />
    );
}

export default LineagePage;
