import { useCallback, useMemo, useState } from "react";
import styles from "./Branches.module.scss";
import axios, { AxiosError } from "axios";
import AxiosLoader from "shared/components/AxiosLoader";
import {
    branchedHome,
    branchedHomeLink,
    branchedLink,
} from "shared/utils/utils";
import { toast_json_error, toast_success } from "shared/utils/toast";
import Table, { Column } from "shared/components/Table";
import SearchBar from "shared/components/search/SearchBar";
import ClipboardPre from "shared/components/ClipboardPre";
import { formatDistanceToNow } from "date-fns";
import Button from "shared/components/Button";
import DeleteIcon from "icons/delete.svg";
import BranchIcon from "icons/branch.svg";
import CommitIcon from "icons/commits.svg";
import ArrowNarrowUpRightIcon from "icons/arrow-narrow-up-right.svg";
import { useNavigate } from "react-router-dom";
import {
    AlertDialog,
} from "shared/components/AlertDialog";
import IconButton from "shared/components/IconButton";
import { DOC_LINKS } from "shared/constants/docs";
import { DeleteConfirmDialog } from "shared/components/DeleteConfirmDialog";

interface Branch {
    name: string;
    total_commits: number;
    last_modified: number;
    owner: string;
}

export const BranchesPage = () => {
    return (
        <div className={styles.gridContainer}>
            <div className={styles.flexContainer}>
                <h2 className={styles.title}>
                    <BranchIcon />
                    Branches
                </h2>
                <JobsTable />
            </div>
        </div>
    );
};

const JobsTable = () => {
    const [reload, setReload] = useState(false);
    const loadFunc = useCallback(() => axios.get("/branch/list"), [reload]);
    return (
        <AxiosLoader
            onload={(branches) => <BranchesTableLoaded branches={branches} setReload={setReload}/>}
            loadFunc={loadFunc}
        />
    );
};

const filterJobs = (allBranches: Branch[], text: string) => {
    return allBranches.filter((branch) =>
        branch.name.toLowerCase().includes(text.toLowerCase())
    );
};

const BranchesTableLoaded = ({
    branches: allBranches,
    setReload
}: {
    branches: Branch[];
    setReload: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [filtered, setFiltered] = useState<Branch[]>(
        allBranches.sort((a, b) => b.last_modified - a.last_modified)
    );

    const [toDelete, setToDelete] = useState<string | undefined>(undefined);

    const navigate = useNavigate();

    const columns: Column<Branch>[] = useMemo(
        () => [
            {
                header: "Branch Name",
                renderFunc: (branch) => {
                    return <BranchName branchName={branch.name} />;
                },
            },
            {
                header: "Last Updated",
                renderFunc: (branch) => (
                    <div
                        title={new Date(
                            branch.last_modified * 1000
                        ).toLocaleString()}
                    >
                        {formatDistanceToNow(
                            new Date(branch.last_modified * 1000)
                        )}{" "}
                        ago
                    </div>
                ),
            },
            {
                header: false,
                renderFunc: (branch) => (
                    <div className={styles.actionButtonContainer}>
                        <Button
                            variant="flat"
                            icon={<CommitIcon />}
                            onClick={() => {
                                navigate(
                                    branchedHomeLink(branch.name, "commits")
                                );
                            }}
                            size="small"
                        >
                            {branch.total_commits} Commits
                        </Button>
                        <Button
                            variant="outline"
                            onClick={() => {
                                navigate(branchedHome(branch.name));
                            }}
                            size="small"
                            icon={<ArrowNarrowUpRightIcon />}
                        >
                            View Branch
                        </Button>
                        {branch.name !== "main" ? (
                            <IconButton
                                icon={<DeleteIcon />}
                                onClick={() => setToDelete(branch.name)}
                                size="small"
                            />
                        ) : (
                            <div className={styles.emptyButton}> </div>
                        )}
                    </div>
                ),
            },
        ],
        []
    );

    return (
        <>
            <div className={styles.search}>
                <SearchBar
                    onSearch={(text) => {
                        setFiltered(filterJobs(allBranches, text));
                    }}
                />
            </div>
            <Table
                className={styles.table}
                data={filtered}
                columns={columns}
                rowKeyFunc={(branch) => branch.name}
                dataUnit="Branch"
                emptyText="You haven't created any branches yet"
                learnMore={DOC_LINKS.branches}
            />
            <AlertDialog
                open={toDelete !== undefined}
                onOpenChange={(open) =>
                    setToDelete((prev) => (!open ? undefined : prev))
                }
            >
                <BranchDeleteDialog
                    branchName={toDelete}
                    onSuccess={() => {
                        setToDelete(undefined);
                        setReload((prev) => !prev);
                    }}
                />
            </AlertDialog>
        </>
    );
};

const BranchName = ({ branchName }: { branchName: string }) => {
    const [tooltipContent, setTooltipContent] = useState("Copy branch name");

    const handleCopyClick = useCallback(() => {
        if (branchName) {
            navigator.clipboard.writeText(branchName);
        }
        // Handle tooltip interaction
        setTooltipContent("Copied!");
        new Promise((res) => setTimeout(res, 2000)).then(() =>
            setTooltipContent("Click to copy")
        );
    }, [branchName]);

    return (
        <ClipboardPre
            tooltip={tooltipContent}
            onCopyClick={handleCopyClick}
            variant="flat"
        >
            {branchName}
        </ClipboardPre>
    );
};


const BranchDeleteDialog = ({
    onSuccess,
    branchName,
}: {
    onSuccess: () => void;
    branchName?: string;
}) => {
    const onSubmit = useCallback(async () => {
        try {
            await await axios.post(branchedLink(branchName, "delete"));
            toast_success("Branch deleted.");
            onSuccess();
        } catch (e) {
            toast_json_error(
                e as AxiosError<{ detail?: string }>,
                "Something went wrong"
            );
        }
    }, [branchName]);

    return (
        <DeleteConfirmDialog
            onSubmit={onSubmit}
            confirmStatement={branchName}
        />
    );
};
