import { stringCompare } from "../utils/utils";
import FeatureIcon from "icons/feature.svg";
import { Column } from "./Table";
import { DOC_LINKS } from "../constants/docs";
import { Feature } from "../utils/types";
import { useUpdateEntityParams } from "../../context/CurrentView";
import { EntityDescriptor, EntityOwner, EntityTable } from "./EntityTable";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    features: Feature[];
    windowed?: boolean;
    emptyText?: string;
    isLearnMore?: boolean;
}

function FeaturesTable({
    features,
    className,
    emptyText,
    isLearnMore,
    windowed,
    ...rest
}: Props): JSX.Element {
    const { updateEntityParams: navigate } = useUpdateEntityParams();

    const columns: Column<Feature>[] = [
        {
            header: "Name",
            renderFunc: (f) => (
                <EntityDescriptor name={f.name} entityIcon={<FeatureIcon/>} metaDescription={f.description}/>
            ),
            sortFunc: (x, y) => stringCompare(x.name, y.name),
        },
        {
            header: "Featureset",
            renderFunc: (f) => (
                <div>
                    <span>{f.featureset}</span>
                </div>
            ),
            sortFunc: (x, y) =>
                stringCompare(x.featureset as string, y.featureset as string),
        },
        {
            header: "Owner",
            renderFunc: (fs) => (
                <EntityOwner owner={fs.owner || ""} />
            ),
            sortFunc: (x, y) => stringCompare(x.owner || "", y.owner || ""),
        },
    ];

    return (
        <EntityTable
            data={features}
            columns={columns}
            rowKeyFunc={(fs) => fs.name + "_" + fs.featureset}
            onRowClick={(row) => navigate("feature", row.name, row.featureset)}
            dataUnit="Feature"
            emptyText={emptyText}
            learnMore={isLearnMore ? DOC_LINKS.featureset : undefined}
            {...rest}
        />
    );
}

export default FeaturesTable;
