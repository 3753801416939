
import classNames from "classnames";
import styles from "../styles/EntityPage.module.scss";

const Metadata = ({ keyLabel, icon, keyValue, flexEnd }: { keyLabel: string, icon: JSX.Element, keyValue: string | JSX.Element, flexEnd?: boolean}) => {
    return (
        <div className={styles.metadataItem}>
            <div
                className={styles.metadataItemKey}
            >
                {icon}
                <span>{keyLabel}</span>
            </div>
            <div
                className={classNames(styles.metadataItemValue,{
                    [styles.flexEnd]: flexEnd
                })}
            >
                <div className={styles.metadataString}>{keyValue}</div>
            </div>
        </div>
    );
};

export default Metadata;
