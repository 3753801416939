import useSWR from "swr";
import styles from "../styles/Ingestion.module.scss";
import {
    axiosFetcherWithParams,
    branchedDatasetLink,
} from "../../../utils/utils";
import {
    Dataset,
} from "../../../utils/types";
import ClockIcon from "icons/timer.svg";
import { parseISO } from "date-fns";
import Spinner from "shared/components/Spinner";

export function LastUpdatedTimestamp({dataset, branchName}: {branchName: string, dataset: Dataset}) {
    const { data, isLoading } = useSWR(
        ["post", branchedDatasetLink(branchName, dataset.name) + "/inspect", {last_n: 1}],
        axiosFetcherWithParams,
    );
    const timestampField = dataset.dsschema.find((ds) => ds.qualifiers.includes("Timestamp"));
    const lastUpdatedDate = timestampField && data?.[0] ? data[0][timestampField?.name] : ""
    return (
        <div className={styles.ingesstionLifetimeRow}>
            <div className={styles.ingestionLifetimeKey}>
                <ClockIcon />
                <div>Latest Timestamp</div>
            </div>
        <div className={styles.ingestionLifetimeAbs}>
            {isLoading ? <Spinner className={styles.spinner}/> :
            lastUpdatedDate ? parseISO(lastUpdatedDate).toLocaleString() : "-"}
        </div>
        </div> 
    );
}
