import FeaturesetIcon from "icons/featureset.svg";
import { stringCompare } from "../utils/utils";
import { Column } from "./Table";
import { DOC_LINKS } from "../constants/docs";
import { useUpdateEntityParams } from "../../context/CurrentView";
import { EntityDescriptor, EntityOwner, EntityTable } from "shared/components/EntityTable";
import { Featureset } from "shared/utils/types";


interface Props extends React.HTMLAttributes<HTMLDivElement> {
    featuresets: Featureset[];
    windowed?: boolean;
    emptyText?: string;
    isLearnMore?: boolean;
}

function FeaturesetsTable({
    featuresets,
    className,
    windowed,
    emptyText,
    isLearnMore,
    ...rest
}: Props): JSX.Element {
    const { updateEntityParams } = useUpdateEntityParams();
    const columns: Column<Featureset>[] = [
        {
            header: "Name",
            renderFunc: (fs) => (
                <EntityDescriptor
                    name={fs.name}
                    entityIcon={<FeaturesetIcon/>}
                    tags={fs.tags}
                    metaDescription={`${fs.features?.length} features`}
                />
            ),
            sortFunc: (x, y) => stringCompare(x.name, y.name),
        },
        {
            header: "Owner",
            renderFunc: (fs) => (
                <EntityOwner owner={fs.owner}/>
            ),
            sortFunc: (x, y) => stringCompare(x.owner, y.owner),
        },
    ];

    return (
        <EntityTable
            data={featuresets}
            columns={columns}
            rowKeyFunc={(fs) => fs.name}
            onRowClick={(row) => {
                updateEntityParams("featureset", row.name);
            }}
            dataUnit="Featureset"
            learnMore={isLearnMore ? DOC_LINKS.featureset : undefined}
            emptyText={emptyText}
            {...rest}
        />
    );
}

export default FeaturesetsTable;
