import { useCallback } from "react";
import { useParams } from "react-router-dom";
import axios, { AxiosError} from "axios";
import { branchedLink, axiosFetcher, nFormatter, elapsedTimeFormatterString, getElapsedTime } from "shared/utils/utils";
import styles from "./QueryOffline.module.scss";
import InspectorPage from "../inspector/shared/InspectorPanel";
import useSWR from "swr";
import EmptyState from "shared/components/EmptyState";
import { ExtractHistoricalJob, ExtractHistoricalJobStatus } from "shared/models";
import Metadata from "shared/components/entityPage/metadataComponents/Metadata";
import WorkflowIcon from "icons/workflow.svg";
import FolderIcon from "icons/folder.svg";
import PieIcon from "icons/pie.svg";
import OwnerIcon from "icons/owner.svg";
import InputIcon from "icons/input.svg";
import OutputIcon from "icons/output.svg";
import StopCircleIcon from "icons/stop-circle.svg";
import WarnIcon from "icons/warning.svg";
import { formatDistanceToNow, parseISO } from "date-fns";
import { ExtractHistoricalIcon } from "shared/components/entityPage/metadataComponents/Ingestion";
import { getInformationForStatus, stoppedStatus } from "./utils";
import ViewOnS3 from "shared/components/entityPage/metadataComponents/ViewOnS3";
import IconButton from "shared/components/IconButton";
import { toast_json_error, toast_success } from "shared/utils/toast";
import Tooltip from "shared/components/Tooltip";

export function QueryOfflineDetail({ request_id }: { request_id: string }): JSX.Element {
    const { branchName } = useParams();
    const { data, isLoading, error } = useSWR<ExtractHistoricalJob>(
        ["get", branchedLink(branchName, `query_offline/${request_id}/status`)],
        axiosFetcher,
        {
            refreshInterval: 120000,
        }
    );
    

    const hasErrors = data?.failure_rate || 0 > 0;

    const handleCancel = useCallback(
        async (request_id: string) => {
            try {
                await axios.post(
                    branchedLink(
                        branchName,
                        `query_offline/${request_id}/cancel`
                    )
                );
                toast_success("Job cancelled");
            } catch (e) {
                toast_json_error(
                    e as AxiosError<{ detail?: string }>,
                    "Something went wrong"
                );
            }
        },
        [branchName]
    );


    return (
        <InspectorPage
            header={{
                title: request_id,
                actions: [
                    hasErrors &&
                    <Tooltip content="View Errors">
                    <IconButton icon={<WarnIcon/>} size="small" onClick={() =>
                        window.open(
                            branchedLink(branchName, "errors") +
                                "?type=ExtractHistorical&origin=" +
                                data?.request_id,
                            "_blank"
                        )}/>
                    </Tooltip>,
                    !stoppedStatus.includes(data?.status as ExtractHistoricalJobStatus) &&
                    <Tooltip content="Cancel Job">
                    <IconButton icon={<StopCircleIcon/>} size="small" onClick={() => data?.request_id ? handleCancel(data.request_id) : null}/>
                    </Tooltip>
                ],
            }}
            sections={isLoading || error ?
                [
                    {
                        title: "Metadata",
                        section: (
                            <EmptyState
                                loading={true}
                            />
                        )
                    }
                ] :
                data ?
                [
                    {
                        title: "Metadata",
                        section: (
                            <MetadataSection
                                job={data}
                            />
                        ),
                    },
                    {
                        title: "Status",
                        section: (
                            <StatusSection
                                job={data}
                            />
                        )
                    },
                    {
                        title: "Output Features",
                        section: (
                            <OutFeatures features={data.output_features} />
                        )
                    }
                ] :
                []
            }
        />
    );
}


function OutFeatures({
    features
}: {
    features: string[]
}) {
    return <>{features.map((feature) => {
        const index = feature.indexOf('.');
        const firstPart = feature.slice(0, index);
        const remainingPart = feature.slice(index + 1);
        return <div key={feature}><span className={styles.muted}>{firstPart}.</span>{remainingPart}</div>
    })}
    </>
}


function MetadataSection({
    job
}: {
    job: ExtractHistoricalJob;
}) {
    return (
        <>
            <Metadata keyLabel="Workflow" icon={<WorkflowIcon />} keyValue={job.workflow || "-"} />
            <Metadata keyLabel="Author" keyValue={job.submitted_by} icon={<OwnerIcon />} />
            <Metadata keyLabel="Started" keyValue={formatDistanceToNow(
                parseISO(`${job.started_at || ""}`),
                {
                    addSuffix: true,
                }
            )} icon={<OwnerIcon />} />
        </>
    );
}


function StatusSection({
    job
}: {
    job: ExtractHistoricalJob
}) {
    const outputPath = `s3://${job.output_bucket}/${job.output_prefix}`;
    const inputPath = `s3://${job.input_bucket}/${job.input_prefix}`;
    return <div className={styles.statusWidget}>
        <div className={styles.statusRow}>
            <div className={styles.statusBlock}>
                <JobStatus status={job.status} completion={job.completion_rate || 0} numberKeys={job.num_cells} />
                <div className={styles.subInfoBlock}>
                    {
                        stoppedStatus.includes(job.status) ?
                            getElapsedTimeForStatus(
                                job.started_at,
                                job.updated_at || "",
                                job.status
                            ) :
                            getInformationForStatus(job.status)
                    }
                </div>
                <div className={styles.separator} />
                <div className={styles.queryStatusDetails}>
                    <div className={styles.queryStatusDetailsTitle}>Query Details</div>
                    <Metadata icon={<PieIcon />} keyLabel="Size" keyValue={job.status == ExtractHistoricalJobStatus.Created ? "Calculating.." : `${nFormatter(job.num_cells, 3)} keys`} flexEnd />
                    <Metadata icon={<InputIcon />} keyLabel="Input Features" keyValue={`${job.input_features.length} features`} flexEnd />
                    <Metadata icon={<FolderIcon />} keyLabel="Input Path" keyValue={<ViewOnS3 path={inputPath} />} flexEnd />
                    <Metadata icon={<OutputIcon />} keyLabel="Output Features" keyValue={`${job.output_features.length} features`} flexEnd />
                    <Metadata icon={<FolderIcon />} keyLabel="Output Path" keyValue={<ViewOnS3 path={outputPath} />} flexEnd />
                </div>
            </div>
        </div>
    </div>
}


const getElapsedTimeForStatus = (
    started_at: string,
    updated_at: string,
    status: ExtractHistoricalJobStatus
) => {
    const timeString =
        elapsedTimeFormatterString(
            getElapsedTime(
                parseISO(started_at).getTime(),
                false,
                !stoppedStatus.includes(status)
                    ? Date.now()
                    : parseISO(updated_at).getTime()
            )
        ) || "< 1 minute";
    const phrase =
        stoppedStatus.includes(status)
            ? "Finished in "
            : "Elapsed ";
    return phrase + timeString;
};



const JobStatus = ({ status, completion, numberKeys }: { status: ExtractHistoricalJobStatus, completion: number, numberKeys: number }) => {

    return <div className={styles.statusTitleContainer}>
        <div className={styles.statusTitle}>
            <ExtractHistoricalIcon status={status} />
            {status.toLowerCase()}
            {!stoppedStatus.includes(status) && "..."}
        </div>
        <div>
            {stoppedStatus.includes(status) ? <>{nFormatter(numberKeys, 4)} total rows</> :
                status !== ExtractHistoricalJobStatus.Created ? <>{(completion*100).toFixed(2)} % complete</> : <></>
            }
        </div>
    </div>
}

