import Table, { Column } from "shared/components/Table";
import AxiosLoader from "shared/components/AxiosLoader";
import styles from "./ErrorsPage.module.scss";
import {
    axiosFetcher,
    branchedLink,
    nFormatter,
    valueCompare,
} from "shared/utils/utils";
import PipelineIcon from "icons/pipeline.svg";
import SourceIcon from "icons/source.svg";
import { useParams, useSearchParams } from "react-router-dom";
import { ErrorLog, ErrorOrigin } from "shared/models";
import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import SearchBar from "shared/components/search/SearchBar";
import { Config, Filter, defaultVerb } from "shared/components/search/filters";
import {
    ErrorOriginTypeFilter,
    ErrorOriginNameFilter,
} from "shared/components/search/ErrorOriginFilter";
import { ERRORS_NAV } from "shared/constants/navigation";
import SummaryModal from "shared/components/SummaryModal";
import Convert from "ansi-to-html";
import ConsolePage from "../console-page/ConsolePage";
import useSWR from "swr";

const convert = new Convert();

const ErrorsPage = () => {
    const { branchName } = useParams();
    const [searchParams] = useSearchParams();
    const originName = searchParams.get("origin");
    const originType = searchParams.get("type");
    const loadFunc = useCallback(
        () =>
            originType == "Pipeline"
                ? Promise.all([
                      axios.post(branchedLink(branchName, "errors"), {
                          origin_name: originName,
                          origin_type: "Pipeline",
                      }),
                      axios.post(branchedLink(branchName, "errors"), {
                          origin_name: originName,
                          origin_type: "Pipeline2",
                      }),
                  ])
                : originType == "Source"
                ? Promise.all([
                      axios.post(branchedLink(branchName, "errors"), {
                          origin_name: originName,
                          origin_type: "Source",
                      }),
                      axios.post(branchedLink(branchName, "errors"), {
                          origin_name: originName,
                          origin_type: "StreamingIngest",
                      }),
                      axios.post(branchedLink(branchName, "errors"), {
                        origin_name: originName,
                        origin_type: "BatchIngestion",
                    }),
                    axios.post(branchedLink(branchName, "errors"), {
                        origin_name: originName,
                        origin_type: "Prepare",
                    }),
                  ])
                : axios.post(branchedLink(branchName, "errors"), {
                      origin_name: originName,
                      origin_type: originType,
                  }),
        [branchName, originName, originType]
    );

    return (
        <AxiosLoader
            onload={(errors: ErrorLog[]) => (
                <ErrorsTableLoaded errors={errors} />
            )}
            loadFunc={loadFunc}
        />
    );
};

function buildSearchConfig(sourceOrigins: ErrorOrigin[]): Config {
    const originTypes = ["Source", "Pipeline", "ExtractHistorical"];
    const originTypeConfig = {
        propertyName: "Type",
        kind: {
            kind: "single-select" as const,
            options: originTypes.map((t) => ({ key: t, name: t, value: t })),
            valueCategory: "type",
            component: ErrorOriginTypeFilter,
            relationship: "has-one" as const,
        },
    };

    const originNamesConfig = {
        propertyName: "Origin",
        kind: {
            kind: "single-select" as const,
            options: sourceOrigins.map((t) => ({
                key: t.origin_name,
                name: t.origin_name,
                value: t.origin_name,
                metadata: { ...t },
            })),
            valueCategory: "origin",
            component: ErrorOriginNameFilter,
            relationship: "has-one" as const,
        },
    };

    const filterOrder = ["type", "origin"];

    return {
        filterOrder,
        filterConfigs: {
            type: originTypeConfig,
            origin: originNamesConfig,
        },
    };
}

function filterErrors(
    errors: ErrorLog[],
    searchText: string,
    filters: Filter[],
    setSearchParams: (params: URLSearchParams) => void,
    force?: boolean
): ErrorLog[] {
    const text = searchText.toLocaleLowerCase();
    const filtered = errors;
    const params = new URLSearchParams();
    if (filters.length) {
        filters.forEach((f) => {
            params.set(f.key.toString(), f.value.toString());
        });
        setSearchParams(params);
    } else if (force) {
        setSearchParams(params);
    }
    return filtered.filter(
        (fs) =>
            searchText == "" || fs.content.toLocaleLowerCase().includes(text)
    );
}

const formatDate = (date: Date) => {
    return date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    });
};

function ErrorSummary({
    error: e,
    onClose,
}: {
    error: ErrorLog;
    onClose: () => void;
}) {
    const date = new Date(e.logged_at / 1000);
    const dateFormatted = formatDate(date);
    const formattedErrorContent = e.content
    .replace(/\\\\/g, '\\')
    .replace(/\\n/g, '<br/>')
    .replace(/\\"/g, '"');   

    return (
        <SummaryModal
            header={dateFormatted}
            onClose={onClose}
            metadataCols={[
                {
                    metadataDetails: <ErrorOriginType type={e.origin_type} />,
                    metadataTitle: "Type",
                },
                {
                    metadataDetails: e.count || 1,
                    metadataTitle: "Count",
                },
                {
                    metadataDetails: e.origin_name,
                    metadataTitle: "Origin",
                },
            ]}
            content={
                <div
                    dangerouslySetInnerHTML={{
                        __html: convert.toHtml(formattedErrorContent),
                    }}
                />
            }
            contentClassName={styles.errorModalContent}
            containerClassName={styles.errorModalContainer}
        />
    );
}

function ErrorsTableLoaded({ errors }: { errors: ErrorLog[] }): JSX.Element {
    const { branchName } = useParams();
    const [filteredErrors, setFilteredErrors] = useState(errors);
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: sourceOrigins } = useSWR(
        ["post", branchedLink(branchName, "list_error_origin")],
        axiosFetcher
    );
    const searchConfig = useMemo(
        () => buildSearchConfig(sourceOrigins || []),
        [sourceOrigins]
    );
    const defaultFilters: Filter[] = [];
    searchParams.forEach((value, key) => {
        if (searchConfig.filterConfigs[key]) {
            defaultFilters.push({
                key,
                value: [value],
                verb: defaultVerb(searchConfig.filterConfigs[key], [value]),
            });
        }
    });

    const [rowDetail, setRowDetail] = useState<ErrorLog | null>(null);
    const columns: Column<ErrorLog>[] = [
        {
            header: "Date",
            renderFunc: (e) => (
                <ErrorDate date={new Date(e.logged_at / 1000)} />
            ),
            sortFunc: (x, y) => valueCompare(x.logged_at, y.logged_at),
        },
        {
            header: "Type",
            renderFunc: (e) => <ErrorOriginType type={e.origin_type} />,
            sortFunc: (x, y) => valueCompare(x.origin_type, y.origin_type),
        },
        {
            header: "Origin",
            renderFunc: (e) => e.origin_name,
            sortFunc: (x, y) => valueCompare(x.origin_name, y.origin_name),
        },
        {
            header: "Count",
            renderFunc: (e) => nFormatter(e.count || 1, 2),
            sortFunc: (x, y) => valueCompare(x.count || 1, y.count || 1),
        },
        {
            header: "Content",
            renderFunc: (e) => <ErrorContent content={e.content} />,
        },
    ];

    return (
        <ConsolePage
            header={{
                title: ERRORS_NAV.title,
                icon: ERRORS_NAV.icon,
            }}
            subheader={
                <SearchBar
                    config={searchConfig}
                    onSearch={(text, filters, force) => {
                        setFilteredErrors(
                            filterErrors(
                                errors,
                                text,
                                filters,
                                setSearchParams,
                                force
                            )
                        );
                    }}
                    defaultFilters={defaultFilters}
                />
            }
            content={
                <>
                    <div className={styles.table}>
                        <Table
                            data={filteredErrors}
                            columns={columns}
                            rowKeyFunc={(e) => e.id}
                            dataUnit="Error"
                            onRowClick={(row) => {
                                setRowDetail(row);
                            }}
                        />
                    </div>
                    {rowDetail && (
                        <ErrorSummary
                            error={rowDetail}
                            onClose={() => setRowDetail(null)}
                        />
                    )}
                </>
            }
        />
    );
}

function ErrorDate({ date }: { date: Date }): JSX.Element {
    return (
        <div className={styles.errorDate}>
            <div className={styles.bar} />
            {formatDate(date)}
        </div>
    );
}

function ErrorOriginType({ type }: { type: string }): JSX.Element {
    let icon = null;
    switch (type.toLocaleLowerCase()) {
        case "pipeline":
            icon = <PipelineIcon width={16} height={16} viewBox="0 0 24 24" />;
            break;
        case "source":
            icon = <SourceIcon width={16} height={16} viewBox="0 0 24 24" />;
            break;
    }

    return (
        <div className={styles.errorOriginType}>
            {icon}
            {type}
        </div>
    );
}

function ErrorContent({ content }: { content: string }): JSX.Element {
    return <div className={styles.errorContent}>{content}</div>;
}

export default ErrorsPage;
