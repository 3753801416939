import { createEdge, createNode } from "shared/components/dag/node.utils";
import { Edge, Node } from "reactflow";
import {
    CurrentViewState,
    Dataset,
    Extractor,
    Feature,
    Featureset,
    Source,
    SourceInfo,
} from "shared/utils/types";
import uniqBy from "lodash/uniqBy";

export function getSourceDependentDatasets(
    primaryDataset: string,
    datasetsWithInputs?: (Dataset & { inputs: string[] })[]
) {
    const datasetQueue = datasetsWithInputs?.filter((d) =>
        d.inputs.includes(primaryDataset)
    );
    if (datasetQueue?.length) {
        const results = [...datasetQueue];
        results.push(
            ...datasetQueue.flatMap((d) =>
                getSourceDependentDatasets(d.name, datasetsWithInputs)
            )
        );
        return results;
    }
    return [];
}

export function getSourceDependentFeatures(
    viewInfo: CurrentViewState,
    datasets?: Dataset[]
) {
    const normalizedFeaturesets = viewInfo.featuresets?.reduce(
        (acc: { [key: string]: Featureset }, curr) => {
            acc[curr.name] = curr;
            return acc;
        },
        {}
    );

    return datasets?.flatMap((d) => {
        return d.featuresets.flatMap(
            (f) => normalizedFeaturesets?.[f.name].features
        );
    });
}

export function getSourceDependencies(viewInfo: CurrentViewState) {
    const sourceMap: SourceInfo = {};
    if (viewInfo.sources?.length) {
        for (const source of viewInfo.sources) {
            sourceMap[source.name] = {
                features: [],
                datasets: [],
                metadata: {
                    name: source.name,
                    table: source.table,
                    source_type: source.source_type,
                },
            };

            const sourcedDataset = source.dataset;
            if (sourcedDataset) {
                const primaryDataset = viewInfo.datasets?.find(
                    (d) => d.name === sourcedDataset
                );
                const dependentDatasets = uniqBy(
                    getSourceDependentDatasets(
                        primaryDataset?.name || "",
                        viewInfo.datasets?.map((d) => ({
                            ...d,
                            inputs: d.pipelines.flatMap(
                                (p) => p.input_dataset_names
                            ),
                        }))
                    ),
                    ({ name }) => name
                );
                if (primaryDataset) {
                    dependentDatasets?.push({ ...primaryDataset, inputs: [] });
                }

                const dependentFeatures = uniqBy(
                    getSourceDependentFeatures(
                        viewInfo,
                        dependentDatasets
                    ) as Feature[],
                    ({ name }) => name
                );

                sourceMap[source.name].features = dependentFeatures;
                sourceMap[source.name].datasets = dependentDatasets;
            }
        }
    }
    return sourceMap;
}

export function getDerivedLabelFromDataset(dataset: Dataset) {
    return {
        prefix: "Derived from",
        suffix: dataset.pipelines.map((pipeline) => pipeline.name).join(" "),
    };
}

export function getSourcedLabelFromDataset(dataSources?: Source[]) {
    if (dataSources?.length && dataSources?.length > 0) {
        return {
            prefix: "Sourced from",
            suffix: dataSources?.map(d => d.source_type).join(' , ')
        };
    } else {
        return {
            prefix: "",
            suffix: "No source",
        };
    }
}

export function getVersionLabelFromCode(extractor: Extractor) {
    let suffix = "";
    let prefix = "Version ";
    const firstLine = extractor.code.split("\n")[0];
    const firstLineWords = firstLine.split(" ");
    if (firstLineWords[1] === "autogenerated") {
        suffix = "Autogenerated";
    }
    const regex = /version=(\d+)/g;
    const possibleMatch = regex.exec(firstLine);
    prefix += possibleMatch?.[1] || 0;
    return {
        prefix,
        suffix,
    };
}

export function buildDAG(branchName: string, viewInfo: CurrentViewState) {
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    const featureSetMap: { [key: string]: string } = {};

    if (viewInfo.sources?.length) {
        const uniqueDBSources: {
            [key: string]: { children: string[]; type: string; output: string };
        } = {};
        for (const source of viewInfo.sources) {
            if (uniqueDBSources[source.db]) {
                uniqueDBSources[source.db].children.push(source.table);
            } else {
                uniqueDBSources[source.db] = {
                    children: [source.table],
                    type: source.source_type,
                    output: source.dataset || "",
                };
            }
        }

        for (const key of Object.keys(uniqueDBSources)) {
            nodes.push(
                createNode({
                    name: key,
                    type: "source",
                    path: "",
                    sublabel: { prefix: uniqueDBSources[key].type, suffix: "" },
                    children: uniqueDBSources[key].children.map((table) => ({
                        label: table,
                        sublabel: "",
                    })),
                    partitionIndex: 1,
                })
            );
        }
    }

    // Extractor Datasets
    if (viewInfo.datasets?.length) {
        for (const dataset of viewInfo.datasets) {
            if (dataset.pipelines.length > 0) {
                nodes.push(
                    createNode({
                        name: dataset.name,
                        type: "dataset",
                        path: dataset.name,
                        sublabel: getDerivedLabelFromDataset(dataset),
                        partitionIndex: 3,
                    })
                );
            } else {
                const datasetSources = viewInfo?.sources?.filter(
                    (source: Source) => source.dataset === dataset.name
                );
                nodes.push(
                    createNode({
                        name: dataset.name,
                        type: "dataset",
                        path: dataset.name,
                        sublabel: getSourcedLabelFromDataset(
                            datasetSources as Source[]
                        ),
                        partitionIndex: 2,
                    })
                );
            }
        }

        for (const dataset of viewInfo.datasets) {
            if (dataset.pipelines.length > 0) {
                for (const pipeline of dataset.pipelines) {
                    const inputDatasetNames = pipeline.input_dataset_names;
                    for (const iDN of inputDatasetNames) {
                        edges.push(
                            createEdge({
                                source: iDN,
                                target: dataset.name,
                            })
                        );
                    }
                }
            } else {
                const datasetSources = viewInfo?.sources?.filter(
                    (source: Source) => source.dataset === dataset.name
                );
                if (datasetSources?.length) {
                    datasetSources.forEach((datasetSource) => {
                        edges.push(
                            createEdge({
                                source: datasetSource?.db || "",
                                target: dataset.name,
                                sourceHandle: datasetSource?.table,
                            })
                        );
                    });
                }
            }
        }
    }

    if (viewInfo.featuresets?.length) {
        for (const featureset of viewInfo.featuresets) {
            if (featureset.extractors.length > 0) {
                for (const extractor of featureset.extractors) {
                    if (extractor.outputs) {
                        for (const output of extractor.outputs) {
                            featureSetMap[featureset.name + "." + output] =
                                featureset.name + "." + extractor.name;
                        }
                    }
                }
                //This is done as some features might not be coming from an extractor, even if an extractor is present
                const extractorOutputs = featureset.extractors.flatMap(
                    (extractor) => extractor.outputs
                );
                const childFeatures = featureset.features.map(
                    (feature) => feature.name
                );
                const extractorOutputsSet = new Set(extractorOutputs);
                const diffSet = childFeatures.filter(
                    (element) => !extractorOutputsSet.has(element)
                );
                if (diffSet.length > 0) {
                    for (const diffElement of diffSet) {
                        featureSetMap[featureset.name + "." + diffElement] =
                            featureset.name;
                    }
                }
            } else {
                for (const feature of featureset.features) {
                    featureSetMap[featureset.name + "." + feature.name] =
                        featureset.name;
                }
            }
        }
        for (const featureset of viewInfo.featuresets) {
            if (featureset.extractors.length > 0) {
                for (const extractor of featureset.extractors) {
                    nodes.push(
                        createNode({
                            name: featureset.name + "." + extractor.name,
                            type: "featureset",
                            path: featureset.name,
                            children: featureset.features
                                .filter(
                                    (f) =>
                                        featureSetMap[
                                            featureset.name + "." + f.name
                                        ] ===
                                        featureset.name + "." + extractor.name
                                )
                                .map((feature) => ({
                                    label: feature.name,
                                    sublabel: "",
                                    path: feature.name,
                                })),
                            sublabel: getVersionLabelFromCode(extractor),
                            partitionIndex: 5,
                        })
                    );
                    if (extractor.inputs) {
                        for (const input of extractor.inputs) {
                            if (
                                featureSetMap[
                                    input.feature_set + "." + input.name
                                ]
                            ) {
                                edges.push(
                                    createEdge({
                                        source: featureSetMap[
                                            input.feature_set + "." + input.name
                                        ],
                                        target:
                                            featureset.name +
                                            "." +
                                            extractor.name,
                                        sourceHandle: input.name,
                                    })
                                );
                            }
                        }
                    }
                    if (extractor.datasets) {
                        for (const dataset of extractor.datasets) {
                            edges.push(
                                createEdge({
                                    source: dataset,
                                    target:
                                        featureset.name + "." + extractor.name,
                                })
                            );
                        }
                    }
                }
                const extractorOutputs = featureset.extractors.flatMap(
                    (extractor) => extractor.outputs
                );
                const childFeatures = featureset.features.map(
                    (feature) => feature.name
                );
                const extractorOutputsSet = new Set(extractorOutputs);
                const diffSet = childFeatures.filter(
                    (element) => !extractorOutputsSet.has(element)
                );
                if (diffSet.length > 0) {
                    nodes.push(
                        createNode({
                            name: featureset.name,
                            type: "featureset",
                            path: featureset.name,
                            children: diffSet.map((feature) => ({
                                label: feature,
                                sublabel: "",
                                path: feature,
                            })),
                            sublabel: { suffix: "", prefix: "No Extractor" },
                            partitionIndex: 4,
                        })
                    );
                }
            } else {
                nodes.push(
                    createNode({
                        name: featureset.name,
                        type: "featureset",
                        path: featureset.name,
                        children: featureset.features.map((feature) => ({
                            label: feature.name,
                            sublabel: "",
                            path: feature.name,
                        })),
                        sublabel: { suffix: "", prefix: "No Extractor" },
                        partitionIndex: 4,
                    })
                );
            }
        }
    }
    return {
        featureSetMap,
        nodes,
        edges,
    };
}
