import { useState } from "react";
import { useParams } from "react-router-dom";

import SearchBar from "shared/components/search/SearchBar";
import { Config, Filter, hasOnePred } from "shared/components/search/filters";

import { stringCompare, branchedLink, axiosFetcher } from "shared/utils/utils";
import {
    ExtractHistoricalJob,
    ExtractHistoricalJobStatus,
} from "shared/models";

import { QUERIES_NAV } from "shared/constants/navigation";
import ConsolePage from "../console-page/ConsolePage";
import styles from "./QueryOffline.module.scss";
import { SortingBox } from "shared/components/sortingOptions/SortingBox";
import { QueryOfflineDisplay } from "./QueryOfflineDisplay";
import useSWR from "swr";
import EmptyState from "shared/components/EmptyState";
import { DOC_LINKS } from "shared/constants/docs";
import { QueryOfflineContext } from "./QueryOfflineContext";
import { sortingOptions, StatusChip } from "./utils";
import UserCircleIcon from "icons/user-circle.svg";
import DropdownIcon from "icons/dropdown.svg";
import OwnerFilter from "shared/components/search/OwnerFilter";


const QueryOffline = () => {
    const { branchName } = useParams();
    const { data, isLoading } = useSWR<ExtractHistoricalJob[]>(
        ["get", branchedLink(branchName, "query_offline/list")],
        axiosFetcher,
        {
            refreshInterval: 120000,
        }
    );

    if (isLoading || !data || data.length === 0) {
        return (
            <ConsolePage
                header={{
                    title: QUERIES_NAV.title,
                    icon: QUERIES_NAV.icon,
                }}
                content={
                    <div className={styles.loaderContainter}>
                        <EmptyState
                            loading={isLoading}
                            text="No queries found"
                            learnMore={DOC_LINKS.queryOffline}
                        />
                    </div>
                }
            />
        );
    }
    return <JobsTableLoaded jobs={data} />;
};

const JobsTableLoaded = ({
    jobs: allJobs,
}: {
    jobs: ExtractHistoricalJob[];
}) => {
    const [filtered, setFiltered] = useState<ExtractHistoricalJob[]>(
        allJobs.sort((a, b) => stringCompare(b.started_at, a.started_at))
    );

    const groupedJobs = filtered.reduce((acc: Record<string, ExtractHistoricalJob[]>, curr) => {
        if (acc[curr.workflow || "default"]) { acc[curr.workflow || "default"].push(curr) } else {
            acc[curr.workflow || "default"] = [curr]
        }

        return acc;
    }, {});

    const [order, setOrder] = useState<"ASC" | "DESC">("ASC");
    const [orderBy, setOrderBy] = useState("submitted_by")
    const searchConfig = buildSearchConfig(allJobs);
    return (
        <QueryOfflineContext.Provider value={{
            order,
            orderBy
        }}>
        <ConsolePage
            header={{
                title: QUERIES_NAV.title,
                icon: QUERIES_NAV.icon,
                actions: [
                    <SortingBox orderBy={orderBy} order={order} orderByKeys={sortingOptions} 
                    onKeyChange={(orderByVal: string)=>setOrderBy(orderByVal)}
                    onOrderChange={(newOrder: "ASC" | "DESC") => setOrder(newOrder)}
                    />
                ]
            }}
            subheader={
                <SearchBar
                    onSearch={(text, filters) => {
                        setFiltered(filterJobs(allJobs, text, filters));
                    }}
                    config={searchConfig}
                />
            }
            content={
                <>
                   <QueryOfflineDisplay groupedJobs={groupedJobs}/>
                </>
            }
        />
        </QueryOfflineContext.Provider>
    );
};


function buildSearchConfig(jobs?: ExtractHistoricalJob[]): Config {
    if (!jobs) {
        return {
            filterOrder: [],
            filterConfigs: {},
        };
    }
    const allOwnersSet = new Set(jobs.map((d) => d.submitted_by.toString()));
    const owners = Array.from(allOwnersSet.keys()).sort();
    const ownerConfig = {
        icon: UserCircleIcon,
        propertyName: "Author",
        kind: {
            kind: "multi-select" as const,
            options: owners.map((o) => ({ key: o, name: o, value: o })),
            valueCategory: "user",
            component: OwnerFilter,
            relationship: "has-one" as const,
        },
    };

    
    const statuses = Array.from([ExtractHistoricalJobStatus.Processing, ExtractHistoricalJobStatus.Complete, ExtractHistoricalJobStatus.Cancelled, ExtractHistoricalJobStatus.Created, ExtractHistoricalJobStatus.Finalizing, ExtractHistoricalJobStatus.Failed ]).sort();
    const statusConfig = {
        icon: DropdownIcon,
        propertyName: "Status",
        kind: {
            kind: "multi-select" as const,
            options: statuses.map((o) => ({ key: o, name: o, value: o })),
            valueCategory: "status",
            component: StatusChip,
            relationship: "has-one" as const,
        },
    };

    const filterOrder = ["owner", "status"];

    return {
        filterOrder,
        filterConfigs: {
            owner: ownerConfig,
            status: statusConfig
        },
    };
}

function filterJobs(
    jobs: ExtractHistoricalJob[],
    searchText: string,
    filters: Filter[]
): ExtractHistoricalJob[] {
    const text = searchText.toLowerCase();
    let filtered = [...jobs].filter(
        (ds) => text == "" || ds.request_id.toLowerCase().includes(text)
    );

    filters.forEach((f) => {
        if (f.key == "owner") {
            filtered = filtered.filter((d) => hasOnePred(f, d.submitted_by));
        }
        else if (f.key == "status") {
            filtered = filtered.filter((d) => hasOnePred(f, d.status));
        }
    });

    return filtered;
}

export default QueryOffline;
