import classNames from "classnames";
import styles from "./styles/Tag.module.scss";
import ChevronRight from "icons/chevron-right.svg";
import ChevronLeft from "icons/chevron-left.svg";

function Tag({
    name,
    size,
    inherited,
    collapsed,
    rowReverse
}: {
    name: string;
    size?: string;
    inherited?: boolean;
    collapsed?: boolean;
    rowReverse?: boolean;
}): JSX.Element {
    return (
        <div className={classNames(size === "small" ? styles.tagContainerSmall : styles.tagContainer, rowReverse ? styles.rowReverse : "")}>
            <div className={styles.tag}>
            {inherited ? (
                <div className={styles.tagCircleOverlap}></div>
            ) : (
                <div className={styles.tagCircle}></div>
            )}
            <span>{name}</span>
            </div>
            {collapsed  ? rowReverse ? <ChevronLeft/> : <ChevronRight /> : null}
        </div>
    );
}

export default Tag;
