import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { DATA_NAV } from "shared/constants/navigation";
import ToggleGroupComponent from "shared/components/ToggleGroup";
import styles from "./DataPage.module.scss";
import ConsolePage from "../console-page/ConsolePage";
import { Dataset, SchemaField } from "shared/utils/types";
import { CurrentViewContext } from "../../context/CurrentView/CurrentViewProvider";
import { branchedDatasetLink, displayFormattedValue } from "shared/utils/utils";
import axios, { AxiosResponse } from "axios";
import Table, { Column } from "shared/components/Table";
import { LookupForm } from "shared/components/Lookup";
import ComboxBox from "shared/components/combobox/Combobox";
import DatasetIcon from "icons/dataset.svg";
import KeyIcon from "icons/key-field.svg";
import ChevronDown from "icons/chevron-down.svg";
import { useUpdateEntityParams } from "../../context/CurrentView";

enum DataType {
    INSPECT = "inspect",
    LOOKUP = "lookup",
}

const SELECTALL = "Select All";
function DataPage(): JSX.Element {
    const { viewInfo } = useContext(CurrentViewContext);
    const [featureTab, setFeatureTab] = useState(DataType.INSPECT);
    const location = useLocation();
    const { updateEntityParams } = useUpdateEntityParams();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (location.hash.length > 0) {
            setFeatureTab(location.hash.substring(1) as DataType);
        }
    }, [location]);

    const datasets = viewInfo?.datasets;
    const initDatasetName = searchParams.get("dataset");

    const [selectedDataset, setSelectedDataset] = useState<Dataset | undefined>();
    const [selectedFields, setSelectedFields] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const initDataset = initDatasetName
            ? datasets?.find((d) => d.name === initDatasetName)
            : undefined;
        setSelectedDataset(initDataset);

        // Initialize selected fields to include all fields by default
        if (initDataset) {
            const fieldSelection = initDataset.dsschema.reduce((acc, field) => {
                acc[field.name] = true; // Set all fields as selected by default
                return acc;
            }, {} as { [key: string]: boolean });
            setSelectedFields(fieldSelection);
        }
    }, [initDatasetName, datasets]);

    const allFields = selectedDataset?.dsschema.map(s => s.name);

    const onFieldClick = (fieldName: string) => {
        if (fieldName === SELECTALL) {
            const selectAll = Object.values(selectedFields).filter(Boolean).length !== allFields?.length;
            const newFieldSelection = allFields?.reduce((acc, curr) => {
                acc[curr] = selectAll;
                return acc;
            }, {} as { [key: string]: boolean });
            setSelectedFields(newFieldSelection || {});
        } else {
            const newFields = {
                ...selectedFields,
                [fieldName]: !selectedFields?.[fieldName],
            };
            setSelectedFields(newFields);
        }
    };

    return (
        <ConsolePage
            customClassNames={{
                container: styles.overflowHidden,
                content: styles.overflowAuto,
            }}
            header={{
                icon: DATA_NAV.icon,
                title: DATA_NAV.title,
            }}
            content={
                featureTab === DataType.LOOKUP ? (
                    <LookupSection dataset={selectedDataset} selectedFields={selectedFields} />
                ) : (
                    <InspectSection dataset={selectedDataset} selectedFields={selectedFields} />
                )
            }
            subheader={
                <Subheader
                    featureTab={featureTab}
                    setFeatureTab={setFeatureTab}
                    datasets={datasets || []}
                    selectedDataset={selectedDataset}
                    onDatasetChange={(datasetName) => {
                        const newDataset = datasets?.find((d) => d.name === datasetName);
                        setSelectedDataset(newDataset);
                        updateEntityParams("dataset", datasetName);
                    }}
                    allFields={allFields}
                    selectedFields={selectedFields}
                    onFieldClick={onFieldClick}
                />
            }
        />
    );
}


interface SubheaderProps {
    featureTab: DataType;
    setFeatureTab: (tab: DataType) => void;
    datasets: Dataset[];
    selectedDataset?: Dataset;
    onDatasetChange: (datasetName: string) => void;
    allFields?: string[];
    selectedFields: { [key: string]: boolean };
    onFieldClick: (fieldName: string) => void;
}

const Subheader = ({
    featureTab,
    setFeatureTab,
    datasets,
    selectedDataset,
    onDatasetChange,
    allFields,
    selectedFields,
    onFieldClick,
}: SubheaderProps) => {
    return (
        <div className={styles.subheader}>
            <div className={styles.toggleContainer}>
                <ToggleGroupComponent
                    items={[
                        {
                            key: DataType.INSPECT,
                            label: "Inspect",
                            value: DataType.INSPECT,
                        },
                        {
                            key: DataType.LOOKUP,
                            label: "Lookup Keys",
                            value: DataType.LOOKUP,
                        },
                    ]}
                    onValueChange={(value) => {
                        if (value && value !== featureTab) {
                            window.location.hash = value;
                            setFeatureTab(value as DataType);
                        }
                    }}
                    activeTab={featureTab}
                />
            </div>
            <DatasetSelector
                datasets={datasets}
                selectedDataset={selectedDataset}
                onDatasetChange={onDatasetChange}
            />
            {selectedDataset && (
                <FieldSelector
                    allFields={allFields}
                    selectedFields={selectedFields}
                    onFieldClick={onFieldClick}
                />
            )}
        </div>
    );
};



interface DatasetSelectorProps {
    datasets: Dataset[];
    selectedDataset?: Dataset;
    onDatasetChange: (datasetName: string) => void;
}

const DatasetSelector = ({
    datasets,
    selectedDataset,
    onDatasetChange,
}: DatasetSelectorProps) => {
    const comboboxItems = datasets.map((d) => {
        const isKeyPresent = d.dsschema.some((s) =>
            s.qualifiers.includes("Key")
        );
        return {
            icon: <DatasetIcon />,
            display: (
                <>
                    {d.name}
                    {isKeyPresent && <KeyIcon />}
                </>
            ),
            value: d.name,
        };
    });

    return (
        <ComboxBox
            items={comboboxItems}
            trigger={
                <div className={styles.combobox}>
                    <div className={styles.comboName}>
                        <DatasetIcon />
                        {selectedDataset ? selectedDataset.name : "None"}
                    </div>
                    <ChevronDown />
                </div>
            }
            isSingleChoice
            onSelectionChange={(selectedValue) => {
                onDatasetChange(selectedValue);
            }}
            selectedValues={[selectedDataset?.name || ""]}
        />
    );
};


interface FieldSelectorProps {
    allFields?: string[];
    selectedFields: { [key: string]: boolean };
    onFieldClick: (fieldName: string) => void;
}

const FieldSelector = ({
    allFields,
    selectedFields,
    onFieldClick,
}: FieldSelectorProps) => {
    const comboBoxFieldItems = allFields?.map((f) => {
        return {
            display: f,
            value: f,
        };
    });

    const selectedFieldCount = Object.values(selectedFields).filter(Boolean)
        .length;

    return (
        <ComboxBox
            items={comboBoxFieldItems!}
            trigger={
                <div className={styles.combobox}>
                    <div className={styles.comboName}>
                        {selectedFieldCount === allFields?.length
                            ? "All Fields"
                            : `${selectedFieldCount} ${selectedFieldCount === 1 ? "Field" : "Fields"}`}
                    </div>
                    <ChevronDown />
                </div>
            }
            includeSelectAll
            onSelectionChange={(selectedValue) => {
                onFieldClick(selectedValue);
            }}
            selectedValues={Object.keys(selectedFields || {}).filter(
                (field) => selectedFields[field]
            )}
        />
    );
};





const LookupSection = ({ dataset, selectedFields }: { dataset?: Dataset, selectedFields?: { [key: string]: boolean } }) => {
    const isKeyPresent = dataset?.dsschema.some((s) =>
        s.qualifiers.includes("Key")
    );
    if (!dataset || !isKeyPresent) {
        return (
            <EmptyDataPageState
                text={"Select a keyed dataset to lookup values"}
            />
        );
    }

    return <LookupForm schema={dataset.dsschema} datasetName={dataset.name} selectedFields={selectedFields}/>;
};

const EmptyDataPageState = ({ text }: { text: string }) => {
    return (
        <div className={styles.emptyState}>
            <div className={styles.emptyStateCenter}>
                <DatasetIcon width={"5rem"} height={"5rem"} />
                <div>{text}</div>
            </div>
        </div>
    );
};

function InspectSection({ dataset, selectedFields }: { dataset?: Dataset, selectedFields?: { [key: string]: boolean } }) {
    if (!dataset) {
        return <EmptyDataPageState text={"Select a dataset to view rows"} />;
    }
    return (
        <InspectTable schema={dataset.dsschema} datasetName={dataset.name} selectedFields={selectedFields}/>
    );
}

function InspectTable({
    schema,
    datasetName,
    selectedFields,
}: {
    schema?: SchemaField[];
    datasetName?: string;
    selectedFields?: { [key: string]: boolean };
}) {
    const { branchName } = useParams();
    const [inspectDataset, setInspectDataset] = useState<
        { [key: string]: string | number | boolean }[]
    >([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(true);
    useEffect(() => {
        setInspectDataset([]);
        setIsLoading(true);
        const url = branchedDatasetLink(branchName, datasetName) + "/inspect";
        axios
            .post(url)
            .then((resp: AxiosResponse<{ [key: string]: string }[]>) => {
                setInspectDataset(resp.data);
            })
            .catch((e) => {
                if (e.response && e.response.status === 401) {
                    setIsAuthorized(false);
                } else {
                    console.error("Error in inspecting dataset");
                }
                setInspectDataset([]);
            })
            .finally(() => setIsLoading(false));
    }, [branchName, datasetName]);
    const keys =
        inspectDataset.length > 0 ? Object.keys(inspectDataset[0]).filter(key => selectedFields?.[key]) : [];
    const columns: Column<any>[] = useMemo(
        () =>
            keys.map((key) => ({
                header: key,
                renderFunc: (data) => (
                    <div>{displayFormattedValue(data[key])}</div>
                ),
                headerRenderFunc: () => (
                    <>
                        <span>{key}</span>
                        <span className={styles.inspect_table_schema}>
                            {schema?.find((d) => d.name == key)?.type}
                        </span>
                    </>
                ),
            })),
        [keys]
    );

    return (
        <Table
            className={styles.inspect_table}
            data={inspectDataset}
            columns={columns}
            emptyText={
                isLoading
                    ? "Searching for latest data.."
                    : isAuthorized
                    ? "No rows founds for this dataset"
                    : "You don't have the required permission to view this data"
            }
            rowKeyFunc={(p) => JSON.stringify(p)}
            dataUnit="row"
            fixedHeader
            divided
            isLoading={isLoading}
        />
    );
}

export default DataPage;
