import {
    ForwardedRef,
    MouseEventHandler,
    cloneElement,
    forwardRef,
} from "react";
import styles from "./styles/IconButton.module.scss";
import classNames from "classnames";

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    icon: JSX.Element;
    size?: "small" | "default" | "large";
    onClick?: MouseEventHandler<HTMLButtonElement>;
    type?: "button" | "submit";
    iconOnly?: boolean;
};

function IconButton(
    {
        className,
        icon,
        onClick,
        size = "default",
        type = "button",
        iconOnly = false,
        ...rest
    }: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
) {
    return (
        <button
            className={classNames(
                iconOnly ? styles.rootIcon : styles.root,
                iconOnly ? null : styles[size],
                className
            )}
            onClick={onClick}
            ref={ref}
            type={type}
            {...rest}
        >
            {cloneElement(icon, {
                width: 16,
                height: 16,
                viewBox: "0 0 24 24",
            })}
        </button>
    );
}

export default forwardRef(IconButton);
