
import Table, { Props as TableProps } from "shared/components/Table"
import styles from "./styles/EntityTable.module.scss";
import { ExpandableTags } from "shared/components/expandableTags/TagRenderer";
import MetaField from "shared/components/MetaField";
import OwnerIcon from "icons/owner.svg";

interface EntityDescriptorProps {
    entityIcon: JSX.Element;
    name: string;
    metaDescription?: string | JSX.Element;
    tags?: string[]
}

export const EntityOwner = ({owner}: {owner: string}) => {
    return <div className={styles.entityOwner}>
        <MetaField icon={<OwnerIcon />} name={owner} />
    </div>
}

export const EntityDescriptor = (props: EntityDescriptorProps) => {
    return <div>
        <div className={styles.entityName}>
            {props.entityIcon}
            <span>
                {props.name}
            </span>
        </div>
        <span className={styles.entityMeta}>{props.metaDescription}</span>
        <span className={styles.entityTags}>
            <ExpandableTags tags={props.tags || []} className={styles.expandableTagsContainer} />
        </span>
    </div>
}


export function EntityTable<T>(props: Partial<TableProps<T>>) {
    return (
        <>
            <Table
                className={styles.entityTable}
                data={props.data || []}
                columns={props.columns || []}
                rowKeyFunc={props.rowKeyFunc || ((row) => JSON.stringify(row))}
                onRowClick={props.onRowClick}
                dataUnit={props.dataUnit || "Entities"}
                emptyText={props.emptyText}
                learnMore={props.learnMore}
            />
        </>
    );
}