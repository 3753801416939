import { useCallback, useContext, useMemo, useState } from "react"
import Avatar from "shared/components/Avatar"
import Chip from "shared/components/Chip"
import ClipboardPre from "shared/components/ClipboardPre"
import { ExtractHistoricalJob, ExtractHistoricalJobStatus } from "shared/models"
import styles from "./QueryOffline.module.scss";
import { elapsedTimeFormatterHMS, getElapsedTime, nFormatter } from "shared/utils/utils"
import TimerIcon from "icons/timer.svg";
import WorkflowIcon from "icons/workflow.svg";
import { parseISO } from "date-fns"
import { useUpdateEntityParams } from "../../context/CurrentView"
import OutputIcon from "icons/output.svg";
import { getInformationForStatus, sortList, StatusChip, stoppedStatus } from "./utils"
import { QueryOfflineContext } from "./QueryOfflineContext"
import Tooltip from "shared/components/Tooltip"
export const QueryOfflineDisplay = ({
    groupedJobs
}: {
    groupedJobs:  Record<string, ExtractHistoricalJob[]>
}) => {
    const { updateEntityParams } = useUpdateEntityParams()
    const {order, orderBy} = useContext(QueryOfflineContext);
    return <>{Object.keys(groupedJobs).map((workflow) => {
        const jobsList = groupedJobs[workflow]
        const sortedList = sortList(jobsList, order as "ASC" | "DESC", orderBy)
        return <div className={styles.groupBody} key={workflow}>
            <div className={styles.groupTitle}>
                <div><GroupChip title={workflow === "default" ? "No workflow" : workflow} isDefault={workflow === "default"}/></div>
                <div>{jobsList.length} queries</div>
            </div>
            <div>
                {sortedList.map((job) => {
                    return <div className={styles.jobRow} onClick={()=>updateEntityParams("query_id", job.request_id)} key={job.request_id}>
                        <div className={styles.jobLeft}>
                        <Tooltip content={getInformationForStatus(job.status)}>
                            <div>
                                <StatusChip name={job.status}/>
                            </div>
                            </Tooltip>
                            <div>
                                <RequestID id={job.request_id}/>
                            </div>
                        </div>
                        <div className={styles.jobRight}>
                            {job.status === ExtractHistoricalJobStatus.Created ? 
                                <div className={styles.muted}>
                                    Calculating Size...
                                </div> 
                            :
                            job.status === ExtractHistoricalJobStatus.Failed ?
                                <div className={styles.muted}>
                                    Errors Detected
                                </div>
                            :
                            <>
                            <div className={styles.info}>
                                {nFormatter(job.num_cells, 3)} rows
                            </div>
                            <Tooltip content={`${job.output_features.length} output features`}>
                                <div className={styles.info}>
                                    {job.output_features.length}
                                    <OutputIcon/>
                                </div>
                            </Tooltip>
                            </>
                            }
                            <div>
                                <Avatar name={job.submitted_by} isServiceAccount={job.is_service_account}/>
                            </div>
                            <div>
                                <ElapsedTime jobStatus={job.status} started_at={job.started_at} updated_at={job.updated_at}/>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
        })}
        </>
}


const ElapsedTime = ({jobStatus, started_at, updated_at}: {jobStatus: ExtractHistoricalJobStatus; started_at: string; updated_at?: string }) => {
        return !stoppedStatus.includes(jobStatus) || !updated_at ? (
            <div className={styles.timer}>
                <TimerIcon />
                {elapsedTimeFormatterHMS(
                    getElapsedTime(
                        parseISO(started_at).getTime(),
                        true
                    )
                )}
            </div>
        ) : (
            <div className={styles.timer}>
                <TimerIcon />
                {elapsedTimeFormatterHMS(
                    getElapsedTime(
                        parseISO(started_at).getTime(),
                        true,
                        parseISO(updated_at).getTime()
                    )
                )}
            </div>
        );
}


const GroupChip = ({title, isDefault}:{title: string, isDefault: boolean}) =>{
    return <Chip icon={<WorkflowIcon/>} color={isDefault ? "default": "primary"}>{title}</Chip>
}


const RequestID = ({ id }: { id: string }) => {
    const [tooltipContent, setTooltipContent] = useState("Copy Request ID");

    const handleCopyClick = useCallback(() => {
        if (id) {
            navigator.clipboard.writeText(id);
        }
        // Handle tooltip interaction
        setTooltipContent("Copied!");
        new Promise((res) => setTimeout(res, 2000)).then(() =>
            setTooltipContent("Click to copy")
        );
    }, [id]);

    const displayId = useMemo(() => {
        const start = id.split("-").shift();
        return `${start}...${id.slice(id.length - 5, id.length)}`;
    }, [id]);

    return (
        <ClipboardPre tooltip={tooltipContent} onCopyClick={handleCopyClick} variant="flat">
            {displayId}
        </ClipboardPre>
    );
};
