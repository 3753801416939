
import styles from "../styles/EntityPage.module.scss";
import AWSIcon from "icons/aws.svg";
import ArrowIcon from "icons/arrow-narrow-up-right.svg";
import CheckCircleIcon from "icons/check-circle.svg";
import CopyIcon from "icons/copy.svg";
import IconButton from "shared/components/IconButton";
import { useCallback, useState } from "react";


function s3ToHttp(url: string): string {
    if (url.startsWith("s3://") || url.startsWith("s3a://") || url.startsWith("s3n://")) {
        const s3Path = url;
        const parts = s3Path.slice(5).split('/');
        const bucket = parts[0];
        const objectName = parts.slice(1).join('/');
        return `https://${bucket}.s3.amazonaws.com/${objectName}`;
    } else {
        return url;
    }
}

const ViewOnS3 = ({ path }: { path: string}) => {

    const [iconToDisplay, setIconToDisplay] = useState<JSX.Element>(<CopyIcon/>)

    const handleCopyClick = useCallback(() => {
        if (path) {
            navigator.clipboard.writeText(path);
        }
        // Handle tooltip interaction
        setIconToDisplay(<CheckCircleIcon/>);
        new Promise((res) => setTimeout(res, 2000)).then(() =>
            setIconToDisplay(<CopyIcon/>)
        );
    }, [path]);
    return (
        <div className={styles.flexItem}>
            <AWSIcon/>
            <a href={s3ToHttp(path)} className={styles.metadataLink} target="_blank">
                <span>View on S3</span>
                <ArrowIcon/>
            </a>
            <IconButton size="small" icon={iconToDisplay} onClick={handleCopyClick} />
        </div>
    );
};

export default ViewOnS3;
