import { useRef, useState } from "react";
import styles from "./TagRenderer.module.scss";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import classNames from "classnames";
export const DirectTags = ({ tags }: { tags: string[] }) => {
    return (
        <>
            {tags.map((tag) => (
                <Tag name={tag} key={tag} size="small" />
            ))}
        </>
    );
};



export function ExpandableTags({ tags, className }: { tags: string[], className?: string }) {
  const ref = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  // Variants for the container that holds the DirectTags
  const containerVariants = {
    expanded: {
      height: 'auto',
      transition: {
        duration: 0.4,
        ease: "easeInOut",
      },
    },
    collapsed: {
      height: 'auto',
      transition: { duration: 0.2, ease: "easeOut" },
    },
  };

  // Variants for each individual tag sliding in from the right
  const tagVariants = {
    hidden: { opacity: 0, x: 50 }, // Start off-screen to the right
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  if(!tags.length) {
    return <></>;
  }

  return (
    <motion.div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={classNames(styles.tagContainer, className)}
    >
      <motion.div
        ref={ref}
        className={styles.expandableTagsLayout}
        animate={isHovered ? "expanded" : "collapsed"}
        variants={containerVariants}
        initial="collapsed"
      >
        {isHovered ? (
          <motion.div className={styles.expandableTags}>
          {tags.map((tag, index) => (
            <motion.div
              key={tag}
              variants={tagVariants}
              initial="hidden"
              animate="visible"
            >
              <Tag name={tag} key={tag} size="small" />
            </motion.div>
          ))}
        </motion.div>
        ) : (
          <Tag
            name={`+${tags.length}`}
            size="small"
            collapsed
            rowReverse
          />
        )}
      </motion.div>
    </motion.div>
  );
}



export const InheritedTags = ({ tags }: { tags: string[] }) => {
    const [expanded, setExpanded] = useState(false);

    const onMouseEnter = () => {
        setExpanded(true);
    };

    const onMouseLeave = () => {
        setExpanded(false);
    };

    return (
        <Tooltip content="Inherited Tags">
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={styles.container}
            >
                {expanded ? (
                    <>
                        {tags.map((tag) => (
                            <Tag name={tag} key={tag} size="small" inherited />
                        ))}
                    </>
                ) : (
                    <Tag
                        name={"+" + tags.length.toString()}
                        key={"collapsed-tags"}
                        size="small"
                        collapsed
                        inherited
                    />
                )}
            </div>
        </Tooltip>
    );
};
