import Button from "../components/Button";
import FileSearchIcon from "icons/file-search.svg";
import ChevronDownIcon from "icons/chevron-down.svg";
import ChevronUpIcon from "icons/chevron-up.svg";
import { useCallback, useState } from "react";

import styles from "./styles/LookupModal.module.scss";
import { toast_error, toast_json_error } from "../utils/toast";
import { useForm, Controller } from "react-hook-form";
import { Field, TextInput } from "./TextInput";
import axios, { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { displayFormattedValue, branchedDatasetLink } from "../utils/utils";
import { Dataset } from "../utils/types";
import Modal from "./Modal";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    schema: Dataset["dsschema"];
    datasetName: string;
    selectedFields?: { [key: string]: boolean };
}

function Lookup({ schema, datasetName }: Props): JSX.Element {
    const [showLookup, setShowLookup] = useState(false);
    return (
        <div>
            <Button
                onClick={() => setShowLookup(true)}
                size="small"
                color="alt"
                variant="outline"
                icon={
                    <FileSearchIcon
                        width={16}
                        height={16}
                        viewBox="0 0 24 24"
                    />
                }
            >
                Lookup Row
            </Button>
            {showLookup && (
                <LookupModal
                    onClose={() => setShowLookup(false)}
                    schema={schema}
                    datasetName={datasetName}
                />
            )}
        </div>
    );
}

const formatLookupData = (
    data: Record<string, string[] | number[] | boolean[] | object[]>,
    fields?: Record<string, boolean>
) => {
    return Object.keys(data).reduce(
        (
            acc: { field: string; value: (typeof data)[string][number] }[],
            curr: string
        ) => {
            if (fields?.[curr]) {
                acc.push({ field: curr, value: data[curr][0] });
            }
            return acc;
        },
        []
    );
};

function LookupModal({
    schema,
    onClose,
    datasetName,
}: ModalProps): JSX.Element {
    return (
        <Modal
            onClose={onClose}
            header={
                <>
                    <FileSearchIcon />
                    <span>Lookup Row</span>
                </>
            }
        >
            <LookupForm schema={schema} datasetName={datasetName} />
        </Modal>
    );
}

interface ModalProps extends Props {
    onClose: () => void;
}

export function LookupForm({ schema, datasetName, selectedFields }: Props): JSX.Element {
    const { branchName } = useParams();
    const keyFields = schema.filter((s) => s.qualifiers.includes("Key"));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm();


    const [lookupTable, setLookupTable] = useState<
        {
            field: string;
            value: string | number | boolean | object;
        }[]
    >([]);

    const onSubmit = useCallback(
        async (values: any) => {
            try {
                setIsSubmitting(true);
                const { as_of, ...rest } = values;
                const formattedDate = as_of
                    ? new Date(as_of).toISOString()
                    : "";
                const formattedKeys = Object.keys(rest).reduce(
                    (acc: Record<string, string[]>, curr: string) => {
                        acc[curr] = [rest[curr]];
                        return acc;
                    },
                    {}
                );
                const lookupResponse = await axios.post(
                    branchedDatasetLink(branchName, datasetName) + "/lookup",
                    {
                        keys: { ...formattedKeys },
                        fields: [],
                        ...(as_of && { timestamps: [formattedDate] }),
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (lookupResponse.data.found[0] === true) {
                    setLookupTable(
                        formatLookupData(
                            lookupResponse.data.data,
                            selectedFields
                        )
                    );
                } else if (lookupResponse.data.found[0] === false) {
                    setLookupTable([]);
                    toast_error(
                        "Data not found for the given fields and timestamp"
                    );
                }
                setIsSubmitting(false);
            } catch (e) {
                setIsSubmitting(false);
                toast_json_error(
                    e as AxiosError<{ detail?: string }>,
                    "Something went wrong"
                );
            }
        },
        [selectedFields]
    );

    return (
        <div className={styles.content}>
            <form
                className={styles.queryBuilder}
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className={styles.queryBuilderContent}>
                    <div className={styles.section}>
                        <div className={styles.subtitle}>Key Fields</div>
                        <div className={styles.keys}>
                            {keyFields.map((key) => (
                                <Controller
                                    control={control}
                                    name={key.name}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Field label={key.name}>
                                            <TextInput {...field} />
                                        </Field>
                                    )}
                                    key={key.name}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.subtitle}>
                            Additional Parameters
                        </div>
                        <div className={styles.keys}>
                            <Controller
                                control={control}
                                name={"as_of"}
                                rules={{ required: false }}
                                render={({ field }) => (
                                    <Field label={"Lookup Value as of"}>
                                        <TextInput
                                            {...field}
                                            type="datetime-local"
                                            step="0.001"
                                        />
                                    </Field>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.queryBuilderFooter}>
                    <Button
                        size="small"
                        color="primary"
                        disabled={!isDirty || !isValid}
                        type="submit"
                        loading={isSubmitting}
                    >
                        Run Lookup
                    </Button>
                </div>
            </form>
            <div className={styles.queryResults}>
                {lookupTable.length > 0 ? (
                    <table className={styles.lookupTable}>
                        <tbody>
                            {lookupTable.map((lookup) => {
                                return (
                                    <tr key={lookup.field}>
                                        <td className={styles.lookupField}>
                                            {lookup.field}
                                        </td>
                                        <td className={styles.lookupValue}>
                                            {displayFormattedValue(
                                                lookup.value
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div className={styles.emptyLookup}>
                        <FileSearchIcon
                            width={32}
                            height={32}
                            viewBox="0 0 40 40"
                        />
                        <div>
                            Enter the key fields to lookup the row in the
                            dataset
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Lookup;
