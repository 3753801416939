import Chip from "shared/components/Chip";
import { ExtractHistoricalIcon } from "shared/components/entityPage/metadataComponents/Ingestion";
import { ExtractHistoricalJob, ExtractHistoricalJobStatus } from "shared/models";
import styles from "./QueryOffline.module.scss";
import Tooltip from "shared/components/Tooltip";
export const stoppedStatus = [ExtractHistoricalJobStatus.Cancelled, ExtractHistoricalJobStatus.Complete, ExtractHistoricalJobStatus.Failed];



export const sortingOptions = [{
    val: "status",
    label: "Status"
}, {
    val: "submitted_by",
    label: "Author"
}, {
    val: "started_at",
    label: "Started At"
}]



export const sortList = (jobsList: ExtractHistoricalJob[], order: "ASC" | "DESC", orderBy: string) => {
    let sortedList = [...jobsList];
    switch (orderBy) {
        case "status":
        case "submitted_by":
            sortedList.sort((a, b) => a[orderBy].localeCompare(b[orderBy]))
            break;
        case "started_at":
            sortedList.sort((a, b) => new Date(a[orderBy]).getTime() - new Date(b[orderBy]).getTime());
            break;
        default:
            break;
    }

    return order == "ASC" ? sortedList : sortedList.reverse();
}

export const getInformationForStatus = (
    status: ExtractHistoricalJobStatus
) => {
    switch (status) {
        case ExtractHistoricalJobStatus.Created:
            return "Preprocessing and calculating query size"
        case ExtractHistoricalJobStatus.Processing:
        case ExtractHistoricalJobStatus.InProgress:
            return "Query is being executed"
        case ExtractHistoricalJobStatus.Finalizing:
            return "Stitching the final output data"
        default:
            return status
    }
}



export const StatusChip = ({ name }: { name: string }) => {
    return <Chip icon={<ExtractHistoricalIcon status={name as ExtractHistoricalJobStatus} />}>
        <span className={styles.jobStatus}>{name}</span>
        </Chip>
}