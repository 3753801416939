import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ENTITIES_NAV } from "shared/constants/navigation";
import ToggleGroupComponent from "shared/components/ToggleGroup";
import styles from "./Entities.module.scss";
import ConsolePage from "../console-page/ConsolePage";
import { CurrentViewContext } from "../../context/CurrentView";
import { Dataset, Featureset, SourceInfo } from "shared/utils/types";
import { EntityType, getSearchConfig, getSearchFilterEntity } from "./utils";
import SearchBar from "shared/components/search/SearchBar";
import FeaturesetsList from "../featureset/FeaturesetsPage";
import FeaturesList from "../featureset/FeaturesPage";
import DatasetsList from "../dataset/DatasetsPage";

const getEntities = (entityType: string, viewInfo: any) => {
    if (entityType === EntityType.FEATURE) {
        return (viewInfo?.featuresets as Featureset[]).flatMap(
            ({ features, name: featureset, owner }) =>
                features.map((f) => ({ ...f, featureset, owner }))
        );
    }
    return viewInfo?.[entityType];
};

function EntititesPage(): JSX.Element {
    const { viewInfo, sourceInfo } = useContext(CurrentViewContext);
    const [featureTab, setFeatureTab] = useState(EntityType.DATASET);
    const location = useLocation();
    const [selectedEntities, setSelectedEntities] = useState(
        getEntities(featureTab, viewInfo)
    );
    const [filtered, setFiltered] = useState(selectedEntities);
    const [defaultFilters, setDefaultFilters] = useState([]);

    useEffect(() => {
        if (location.hash.length > 0) {
            const maybeFeatureTab = location.hash.substring(1) as EntityType;
            const newEntities = getEntities(maybeFeatureTab, viewInfo)
            setFeatureTab(maybeFeatureTab);
            setSelectedEntities(newEntities);
            setFiltered(newEntities);
        }
    }, [location]);

    const searchConfig = getSearchConfig(featureTab)(
        selectedEntities,
        sourceInfo as SourceInfo
    );
    const filterEntity = getSearchFilterEntity(featureTab);

    return (
        <ConsolePage
            header={{
                icon: ENTITIES_NAV.icon,
                title: ENTITIES_NAV.title,
            }}
            content={
                featureTab === EntityType.DATASET ? (
                    <DatasetsList
                        filteredDatasets={filtered}
                        allDatasets={selectedEntities as Dataset[]}
                    />
                ) : featureTab === EntityType.FEATURE ? (
                    <FeaturesList
                        filteredFeatures={filtered}
                        features={selectedEntities}
                    />
                ) : (
                    <FeaturesetsList
                        filteredFeaturesets={filtered}
                        featuresets={selectedEntities}
                    />
                )
            }
            subheader={
                <div className={styles.subheader}>
                    <div className={styles.toggleContainer}>
                        <ToggleGroupComponent
                            items={[
                                {
                                    key: EntityType.DATASET,
                                    label: "Datasets",
                                    value: EntityType.DATASET,
                                },
                                {
                                    key: EntityType.FEATURE,
                                    label: "Features",
                                    value: EntityType.FEATURE,
                                },
                                {
                                    key: EntityType.FEATURESET,
                                    label: "Featuresets",
                                    value: EntityType.FEATURESET,
                                },
                            ]}
                            onValueChange={(value) => {
                                if (value) {
                                    if (value != featureTab) {
                                        window.location.hash = value;
                                    }
                                    setFeatureTab(value as EntityType);
                                    setSelectedEntities(
                                        getEntities(value, viewInfo)
                                    );
                                    setFiltered(getEntities(value, viewInfo));
                                    setDefaultFilters([]);
                                }
                            }}
                            activeTab={featureTab}
                        />
                    </div>
                    <SearchBar
                        config={searchConfig}
                        onSearch={(text, filters) => {
                            setFiltered(
                                filterEntity(
                                    selectedEntities,
                                    text,
                                    filters,
                                    sourceInfo as SourceInfo
                                )
                            );
                        }}
                        expandSearch
                        defaultFilters={defaultFilters}
                    />
                </div>
            }
        />
    );
}

export default EntititesPage;
