import { toISOString } from "shared/components/date-picker/date-types";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./DashboardPage.module.scss";
import axios, { AxiosResponse } from "axios";
import { branchedFeatureLink, branchedMetricsLink } from "shared/utils/utils";
import {
    avgY,
    DataPoint,
    getMinMax,
    parsePromResponse,
    PromResponse,
} from "shared/utils/prometheus";
import {
    DEFAULT_STROKE,
    FULL_GRAPH_HEIGHT,
    FULL_GRAPH_WIDTH,
    StrokeStyle,
} from "shared/components/graph/Graph";
import NumberSeries from "shared/components/graph/NumberSeries";
import InteractiveGraph from "shared/components/graph/InteractiveGraph";

export interface FeatureGraphProps {
    startDate: Date;
    endDate: Date;
    featureName?: string | null;
    featuresetName?: string | null;
}

export interface ExtractorGraphProps {
    startDate: Date;
    endDate: Date;
    extractorName?: string | null;
}

interface LatencyData {
    p50: DataPoint[];
    p75: DataPoint[];
    p90: DataPoint[];
    p99: DataPoint[];
    avgP50: number;
    avgP75: number;
    avgP90: number;
    avgP99: number;
}

export function ExtractorLatency({
    startDate,
    endDate,
    extractorName,
}: ExtractorGraphProps) {
    const { branchName } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState<LatencyData>();
    useEffect(() => {
        setIsLoaded(false);
        axios
            .post(branchedMetricsLink(branchName, "extractor_latency"), {
                extractor_name: extractorName,
                start: toISOString(startDate),
                end: toISOString(endDate),
            })
            .then((resp: AxiosResponse) => {
                const p50 = parsePromResponse(resp.data.p50);
                const p75 = parsePromResponse(resp.data.p75);
                const p90 = parsePromResponse(resp.data.p90);
                const p99 = parsePromResponse(resp.data.p99);
                const _data = {
                    p50,
                    p75,
                    p90,
                    p99,
                    avgP50: avgY(p50),
                    avgP75: avgY(p75),
                    avgP90: avgY(p90),
                    avgP99: avgY(p99),
                };
                setData(_data);
            })
            .catch((err) => {
                setData(undefined);
                console.error(err);
            })
            .finally(() => setIsLoaded(true));
    }, [startDate, endDate, extractorName]);
    const yAccessor = (d: { y: number }) => d.y * 1000;

    const configs = {
        p50: {
            stroke: "#2C2D3A",
            lineLabel: "p50",
            strokeStyle: "dotted" as StrokeStyle,
            yAccessor,
        },
        p75: {
            stroke: "#686779",
            lineLabel: "p75",
            strokeStyle: "dotted" as StrokeStyle,
            yAccessor,
        },
        p90: {
            stroke: "#C2C0D3",
            lineLabel: "p90",
            strokeStyle: "dotted" as StrokeStyle,
            yAccessor,
        },
        p99: {
            stroke: "#0D0B19",
            lineLabel: "p99",
            strokeStyle: "dotted" as StrokeStyle,
            yAccessor,
        },
    };

    return (
        <div className={styles.graphContainer}>
            <div className={styles.graphHeader}>
                <div className={styles.graphTitle}>Latency</div>
                <div className={styles.graphSubtitle}>
                    Latency b/w extractor execute is called and time taken by
                    the actual execution
                </div>
                <InteractiveGraph
                    startDate={startDate}
                    endDate={endDate}
                    data={
                        data && {
                            p50: data.p50,
                            p75: data.p75,
                            p90: data.p90,
                            p99: data.p99,
                        }
                    }
                    isLoaded={isLoaded}
                    configs={configs}
                />
            </div>
        </div>
    );
}

export function FeatureActivity({
    startDate,
    endDate,
    featureName,
    featuresetName,
}: FeatureGraphProps): JSX.Element {
    const { branchName } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    return (
        <div className={styles.graphContainer}>
            <div className={styles.graphHeader}>
                <div className={styles.graphTitle}>Activity</div>
                <div className={styles.graphSubtitle}>
                    Rate of query on features over time
                </div>
            </div>
            <div className={styles.graph}>
                <NumberSeries
                    startDate={startDate}
                    endDate={endDate}
                    height={FULL_GRAPH_HEIGHT}
                    width={FULL_GRAPH_WIDTH}
                    lineLabel="Extracted"
                    stroke={DEFAULT_STROKE}
                    loadFunc={() => {
                        return axios
                            .post(branchedMetricsLink(branchName, "feature"), {
                                featureset: featuresetName,
                                feature: featureName,
                                start: toISOString(startDate),
                                end: toISOString(endDate),
                            })
                            .then((resp: AxiosResponse<PromResponse>) =>
                                parsePromResponse(resp.data)
                            )
                            .finally(() => setIsLoaded(true));
                    }}
                    isLoaded={isLoaded}
                />
            </div>
        </div>
    );
}

interface DistData {
    mean: DataPoint[];
    lower: DataPoint[];
    upper: DataPoint[];
}

export function DistributionSection({
    startDate,
    endDate,
    featuresetName,
    featureName,
}: FeatureGraphProps): JSX.Element {
    const { branchName } = useParams();
    const [distData, setDistData] = useState<DistData>();

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(false);
        const featureLink = branchedFeatureLink(
            branchName,
            featuresetName || "",
            featureName || ""
        );
        const link = `${featureLink}/distributions`;
        axios
            .post(link, {
                featureset: featuresetName,
                feature: featureName,
                start: toISOString(startDate),
                end: toISOString(endDate),
            })
            .then((resp: AxiosResponse) => {
                const data = {
                    mean: parsePromResponse(resp.data.mean),
                    lower: parsePromResponse(resp.data.lower),
                    upper: parsePromResponse(resp.data.upper),
                };
                setDistData(data);
            })
            .finally(() => setIsLoaded(true));
    }, [startDate, endDate, featureName, featuresetName]);
    const lMinMax = useMemo(() => getMinMax(distData?.lower || []), [distData]);
    const uMinMax = useMemo(() => getMinMax(distData?.upper || []), [distData]);
    const minMax = lMinMax && uMinMax && { min: lMinMax.min, max: uMinMax.max };

    return (
        <div className={styles.graphContainer}>
            <div className={styles.graphHeader}>
                <div className={styles.graphTitle}>Feature Distribution</div>
                <div className={styles.graphSubtitle}>
                    Changes in the probability distribution of a feature
                </div>
                <InteractiveGraph
                    startDate={startDate}
                    endDate={endDate}
                    data={{ ...distData }}
                    yDomain={minMax}
                    isLoaded={isLoaded}
                    configs={{
                        mean: {
                            stroke: "#655ce9",
                            lineLabel: "Mean",
                        },
                        lower: {
                            stroke: "#261584",
                            lineLabel: "-σ",
                            strokeStyle: "dotted",
                        },
                        upper: {
                            stroke: "#261584",
                            lineLabel: "+σ",
                            strokeStyle: "dotted",
                        },
                    }}
                />
            </div>
        </div>
    );
}
