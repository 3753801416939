import { useCallback, useState } from "react";
import axios, { AxiosError } from "axios";

import { toast_json_error, toast_success } from "../../../shared/utils/toast";

import Button from "../../../shared/components/Button";
import SettingsCard from "../shared/SettingsCard/SettingsCard";
import styles from "./styles/UpdatePasswordForm.module.scss";
import SelectInput, { SelectItem } from "shared/components/SelectInput";
import { useCurrentUser } from "../../../context/CurrentUser";
import { LOGOUT_INACTIVE_TIME } from "shared/constants/constants";
import ClosedEyeIcon from "icons/closed-eye.svg";

const getDurationOptions = () => {
    const result = [];
    result.push({display:"30 mins", value: LOGOUT_INACTIVE_TIME})
    for(let i = 0; i<24; i++) {
        result.push({display: `${i+1} hour${i>0 ? 's' :''}`,  value:(i+1)*60*60*1000})
    }
    return result;
}


function ConfigureAutoLogout() {
    const {autoLogoutDuration} = useCurrentUser()
    const [autoLogout, setAutoLogout] = useState((autoLogoutDuration || LOGOUT_INACTIVE_TIME).toString());
    const durationOptions = getDurationOptions();


    const onSaveHandler = useCallback(async () => {
        try {
        await axios.post("/team/autologout", {
            autologout_duration: autoLogout
        })
        toast_success("Settings Saved");
        } catch(e) {
            toast_json_error(
                e as AxiosError<{ detail?: string }>,
                "Something went wrong"
            );
        }
    }
    , [autoLogout])

    return (
        <SettingsCard Footer={
        <AutoSettingsFooter 
            disabled={autoLogout === autoLogoutDuration?.toString()} 
            onSave={onSaveHandler}
        />}>
            <div className={styles.content}>
                <div className={styles.title}>
                    <p>Session Timeout</p>
                    <p>Configure the duration of inactive sessions before auto logout</p>
                </div>
                <div className={styles.input_wrapper}>
                            <SelectInput
                                onChange={(v) => {
                                    setAutoLogout(v)
                                }}
                                value={autoLogout}
                                className={styles.select}
                            >
                                {durationOptions.map((d) => 
                                    <SelectItem value={d.value.toString()} key={d.value}>
                                        {d.display}
                                    </SelectItem>
                                )}
                            </SelectInput>
                </div>
            </div>
        </SettingsCard>
    );
}


const AutoSettingsFooter = ({disabled, onSave}: {
    disabled: boolean,
    onSave: () => void
}) => {
    const [isLoading, setLoading] = useState(false);
    const saveHandler = () => {
        setLoading(true);
        onSave();
        setLoading(false);
    }
    return <div className={styles.content} style={{width: '100%'}}>
        <div className={styles.footerContent}>
            <ClosedEyeIcon/>
            <span>Only visible to Super Admins</span>
        </div>
        <div className={styles.input_wrapper}>
                <Button color="primary" disabled={disabled} onClick={saveHandler} loading={isLoading}>
                    Save
                </Button>
        </div>
    </div>
    
}




export default ConfigureAutoLogout;
