import {
    DateValue,
    convertToDate,
    toISOString,
} from "shared/components/date-picker/date-types";
import DateRangeControl from "shared/components/date-picker/DateRangeControl";
import { useContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
    FILTER_META_SEPARATOR,
    INIT_END_DATE,
    INIT_START_DATE,
} from "shared/constants/constants";
import styles from "./DashboardPage.module.scss";
import { DASHBOARD_NAV } from "shared/constants/navigation";
import ConsolePage from "../console-page/ConsolePage";
import ToggleGroupComponent from "shared/components/ToggleGroup";
import SearchBar from "shared/components/search/SearchBar";
import { getSearchConfig, MetricType } from "./utils";
import {
    CurrentViewContext,
    useUpdateEntityParams,
} from "../../context/CurrentView";
import { Config, Filter } from "shared/components/search/filters";
import {
    BacklogGraph,
    DatasetActivityGraph,
    DatasetGraphProps,
    DatasetSizeGraph,
    ErrorsGraph,
    ExpectationSection,
    LastProcessedGraph,
} from "./DatasetGraphs";
import {
    DistributionSection,
    ExtractorLatency,
    FeatureActivity,
    FeatureGraphProps,
} from "./FeatureGraphs";
import { CurrentViewState } from "../../shared/utils/types";

function DashboardPage(): JSX.Element {
    const { viewInfo } = useContext(CurrentViewContext);

    const [startDateValue, setStartDateValue] =
        useState<DateValue>(INIT_START_DATE);
    const [endDateValue, setEndDateValue] = useState<DateValue>(INIT_END_DATE);

    const [searchParams, setSearchParams] = useSearchParams();
    const paramFromDate = searchParams.get("fromDate");
    const paramToDate = searchParams.get("toDate");
    const startDate = paramFromDate
        ? new Date(paramFromDate as string)
        : convertToDate(startDateValue);
    const endDate = paramToDate
        ? new Date(paramToDate as string)
        : convertToDate(endDateValue);

    const location = useLocation();
    const [featureTab, setFeatureTab] = useState(MetricType.DATASET);
    const { updateEntityParams: navigate, clearEntityParams } =
        useUpdateEntityParams();
    const searchConfig: Config = getSearchConfig(
        featureTab,
        viewInfo as CurrentViewState
    )();

    const dataset_name = searchParams.get("dataset");
    const feature_name = searchParams.get("feature");
    const featureset_name = searchParams.get("featureset");

    const [defaultFilters, setDefaultFilters] = useState<Filter[]>([]);

    useEffect(() => {
        if (dataset_name) {
            setDefaultFilters([
                {
                    key: "dataset",
                    value: [dataset_name],
                    verb: "is",
                },
            ]);
        }
        if (feature_name && featureset_name) {
            setDefaultFilters([
                {
                    key: "feature",
                    value: [
                        feature_name + FILTER_META_SEPARATOR + featureset_name,
                    ],
                    verb: "is",
                },
            ]);
        }
    }, [dataset_name, feature_name]);

    useEffect(() => {
        if (location.hash.length > 0) {
            setFeatureTab(location.hash.substring(1) as MetricType);
        } else if (dataset_name) {
            setFeatureTab(MetricType.DATASET);
        } else if (feature_name) {
            setFeatureTab(MetricType.FEATURE);
        }
    }, [location]);

    return (
        <ConsolePage
            header={{
                title: DASHBOARD_NAV.title,
                icon: DASHBOARD_NAV.icon,
                actions: [
                    <DateRangeControl
                        onDateUpdate={(fromDate, toDate) => {
                            searchParams.set("fromDate", toISOString(fromDate));
                            searchParams.set("toDate", toISOString(toDate));
                            setSearchParams(searchParams);
                            setStartDateValue(fromDate);
                            setEndDateValue(toDate);
                        }}
                        initialFromDate={
                            paramFromDate ? startDate : startDateValue
                        }
                        initialToDate={paramFromDate ? endDate : endDateValue}
                        alignment="right"
                        key="date-range"
                    />,
                ],
            }}
            subheader={
                <div className={styles.subheader}>
                    <div className={styles.toggleContainer}>
                        <ToggleGroupComponent
                            items={[
                                {
                                    key: MetricType.DATASET,
                                    label: "Datasets",
                                    value: MetricType.DATASET,
                                },
                                {
                                    key: MetricType.FEATURE,
                                    label: "Features",
                                    value: MetricType.FEATURE,
                                },
                            ]}
                            onValueChange={(value) => {
                                if (value) {
                                    if (value != featureTab) {
                                        window.location.hash = value;
                                        setDefaultFilters([]);
                                    }
                                    setFeatureTab(value as MetricType);
                                }
                            }}
                            activeTab={featureTab}
                        />
                    </div>
                    <SearchBar
                        config={searchConfig}
                        onSearch={(text, filters, force) => {
                            if (force) {
                                clearEntityParams();
                            }
                            filters.forEach((f) => {
                                if (f.key === MetricType.DATASET) {
                                    navigate("dataset", f.value.toString());
                                } else if (f.key === MetricType.FEATURE) {
                                    const [feature, featureset] = f.value
                                        .toString()
                                        .split(FILTER_META_SEPARATOR);
                                    navigate("feature", feature, featureset);
                                }
                            });
                        }}
                        searchHide
                        defaultFilters={
                            defaultFilters.length > 0 ? defaultFilters : undefined
                        }
                    />
                </div>
            }
            content={
                featureTab === MetricType.DATASET ? (
                    <DatasetMetrics startDate={startDate} endDate={endDate} />
                ) : (
                    <FeatureMetrics startDate={startDate} endDate={endDate} />
                )
            }
        />
    );
}

const DatasetMetrics = ({ startDate, endDate }: DatasetGraphProps) => {
    const { viewInfo } = useContext(CurrentViewContext);
    const [searchParams] = useSearchParams();
    const datasetName = searchParams.get("dataset");
    const maybeDataset = viewInfo?.datasets?.find(
        (d) => d.name === datasetName
    );

    return (
        <div className={styles.graphsContainer}>
            <DatasetActivityGraph
                startDate={startDate}
                endDate={endDate}
                dataset={maybeDataset}
            />
            {maybeDataset && !maybeDataset.is_source_dataset && (
                <DatasetSizeGraph
                    startDate={startDate}
                    endDate={endDate}
                    dataset={maybeDataset}
                />
            )}
            {maybeDataset && !maybeDataset.is_source_dataset && (
                <BacklogGraph
                    startDate={startDate}
                    endDate={endDate}
                    dataset={maybeDataset}
                />
            )}
            <LastProcessedGraph
                startDate={startDate}
                endDate={endDate}
                dataset={maybeDataset}
            />
            <ErrorsGraph
                startDate={startDate}
                endDate={endDate}
                dataset={maybeDataset}
            />
            {maybeDataset && maybeDataset.is_expectation_defined && (
                <ExpectationSection
                    startDate={startDate}
                    endDate={endDate}
                    dataset={maybeDataset}
                />
            )}
        </div>
    );
};

const FeatureMetrics = ({ startDate, endDate }: FeatureGraphProps) => {
    const { viewInfo } = useContext(CurrentViewContext);
    const [searchParams] = useSearchParams();
    const featureName = searchParams.get("feature");
    const featuresetName = searchParams.get("featureset");
    const maybeFeatureset = viewInfo?.featuresets?.find(
        (f) => f.name === featuresetName
    );
    let extractorName = undefined;

    if (maybeFeatureset) {
        const extractor = maybeFeatureset.extractors.find((e) =>
            e.outputs?.includes(featureName as string)
        );
        if (extractor) {
            extractorName = featuresetName + "." + extractor.name;
        }
    }

    return (
        <div className={styles.graphsContainer}>
            <FeatureActivity
                startDate={startDate}
                endDate={endDate}
                featureName={featureName}
                featuresetName={featuresetName}
            />
            <ExtractorLatency
                startDate={startDate}
                endDate={endDate}
                extractorName={extractorName}
            />
            {featureName && featuresetName && (
                <DistributionSection
                    startDate={startDate}
                    endDate={endDate}
                    featureName={featureName}
                    featuresetName={featuresetName}
                />
            )}
        </div>
    );
};

export default DashboardPage;
